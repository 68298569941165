import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import "./HamburgerMenu.css";
import CodeIcon from "@mui/icons-material/Code";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

export default function HamburgerMenu(props) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          {
            text: "Frontend Engineer",
            icon: <CodeIcon />,
            link: "/checkout",
          },
          {
            text: "Python for Beginners",
            icon: <FreeBreakfastIcon />,
            link: "/python101",
            disabled: true,
          },
          {
            text: "Career Coaching",
            icon: <EmojiPeopleIcon />,
            link: "/careercoaching",
          },
        ].map((item, index) => (
          <ListItem key={item.text} disablePadding>
            <Link
              to={item.disabled ? "" : item.link}
              className="drawer-list-item"
            >
              <ListItemButton disabled={item.disabled}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  const anchor = <MenuIcon />;
  return (
    <div>
      <React.Fragment key={"anchor"}>
        <Button
          onClick={toggleDrawer("anchor", true)}
          className={props.buttonClass}
        >
          {anchor}
        </Button>
        <Drawer
          anchor={"left"}
          open={state["anchor"]}
          onClose={toggleDrawer("anchor", false)}
        >
          {list("anchor")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
