import React, { Fragment } from "react";
import "./CourseVideo.css";
import osoService from "constants/settings";
import { logError } from "utils/log_utils";
import { AuthContext } from "contexts/Context";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import BoltIcon from "@mui/icons-material/Bolt";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import ClosedCaptionDisabledIcon from "@mui/icons-material/ClosedCaptionDisabled";
import refreshToken from "utils/authUtils";
import lockedVideoPoster from "images/lockedVideoPoster.png";
import underConstructionPoster from "images/under-construction.png";
import Spinner from "components/Spinner";
import { Button } from "@mui/material";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

class CourseVideo extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.courseVideoRef = React.createRef();
    this.state = {
      loading: false,
      wait: false,
      videoSrc: null,
      captionsSrc: null,
      captionMode: "disabled",
    };
  }

  componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      () => this.getVideo(this.props.videoName)
    );
  }

  // This was my attempt at making two caption buttons (built-in and mine) play nice
  // The issue was the it creates a need for double tap for the added caption button
  // somehow the event listener is creating unexpected side effect or 2 event listeners are being added
  // componentDidUpdate() {
  //   if (!this.state.componentUpdateCountForTrack) {
  //     if (this.courseVideoRef.current) {
  //       if (this.courseVideoRef.current.textTracks.addEventListener.length) {
  //         console.log(
  //           this.courseVideoRef.current.textTracks.addEventListener.length
  //         );
  //       }
  //       this.courseVideoRef.current.textTracks.addEventListener(
  //         "change",
  //         () =>
  //           this.setState({
  //             captionMode:
  //               this.state.captionButton === "showing" ? "disabled" : "showing",
  //             componentUpdateCountForTrack: true,
  //           }),
  //         false
  //       );
  //     }
  //   }
  // }

  speedUp = (rate) => {
    if (this.courseVideoRef.current) {
      this.courseVideoRef.current.playbackRate = rate;
    }
  };

  toggleCaptions = () => {
    if (this.courseVideoRef.current) {
      const captionMode =
        this.state.captionMode === "showing" ? "disabled" : "showing";
      this.courseVideoRef.current.textTracks[0].mode = captionMode;
      this.setState({ captionMode });
    }
  };

  async getVideo(videoName) {
    if (
      !videoName ||
      videoName === "LOCKED" ||
      videoName === "UNDER_CONSTRUCTION"
    ) {
      this.setState({
        loading: false,
      });
      return;
    }
    this.setState({
      wait: true,
    });
    // wait to refresh token before calling the api
    const idToken = await refreshToken(this.context.user);

    fetch(`${osoService}/get_video`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        video_name: videoName,
        email: this.context.user.email,
        id_token: idToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const video = data.video;
        const captions = data.captions;
        this.setState({
          videoSrc: video,
          captionsSrc: captions,
        });
      })
      .catch((e) => {
        logError(e);
      })
      .finally(() => {
        this.setState({
          wait: true,
          loading: false,
        });
      });
  }

  renderSpeedDial() {
    const speedDialActions = [
      {
        icon: <LooksOneIcon />,
        name: "Normal Speed",
        actionFunc: () => this.speedUp(1),
      },
      {
        icon: <BoltIcon />,
        name: "1.75 Speed",
        actionFunc: () => this.speedUp(1.75),
      },
      {
        icon:
          this.courseVideoRef.current &&
          this.courseVideoRef.current.textTracks[0].mode &&
          this.courseVideoRef.current.textTracks[0].mode === "showing" ? (
            <ClosedCaptionDisabledIcon />
          ) : (
            <ClosedCaptionIcon />
          ),
        name: "Captions",
        actionFunc: () => this.toggleCaptions(),
      },
    ];

    return (
      <Box
        sx={{
          position: "relative",
          mt: 2,
          height: 20,
          left: "8.5%",
          top: "8px",
          marginTop: "0px",
        }}
      >
        <StyledSpeedDial
          ariaLabel="SpeedDial"
          hidden={false}
          icon={<SpeedDialIcon />}
          direction="right"
          FabProps={{ size: "small", style: { backgroundColor: "gray" } }}
        >
          {speedDialActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.actionFunc}
            />
          ))}
        </StyledSpeedDial>
      </Box>
    );
  }

  renderVideo = () => {
    if (this.props.videoName === "LOCKED") {
      return (
        <Fragment>
          <video
            width="60%"
            className="course-vid"
            controls
            controlsList="nodownload"
            crossOrigin="ananymous"
            poster={lockedVideoPoster}
          >
            <source src="" type="video/mp4" />
          </video>
          <div>
            <Button
              variant="contained"
              color="success"
              component={Link}
              to="/checkout"
              className="enroll-button-under-video"
            >
              Enroll
            </Button>
          </div>
        </Fragment>
      );
    }
    if (this.props.videoName === "UNDER_CONSTRUCTION") {
      return (
        <video
          width="60%"
          className="course-vid"
          controls
          controlsList="nodownload"
          crossOrigin="ananymous"
          poster={underConstructionPoster}
        >
          <source src="" type="video/mp4" />
        </video>
      );
    }
    if (this.state.videoSrc && this.state.captionsSrc) {
      return (
        <Fragment>
          <video
            width="60%"
            className="course-vid"
            ref={this.courseVideoRef}
            controls
            controlsList="nodownload"
            crossOrigin="ananymous"
          >
            <source src={this.state.videoSrc} type="video/mp4" />
            <track
              src={this.state.captionsSrc}
              kind="captions"
              srcLang="en"
              label="English"
            />
          </video>
          {this.renderSpeedDial()}
        </Fragment>
      );
    }
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    return <div className="course-video">{this.renderVideo()}</div>;
  }
}

export default withRouter(CourseVideo);
