import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyBbH0HwEQO4rV1SNCut9_SUH9qt2OxH5Ss",
  authDomain: "arash-317602.firebaseapp.com",
  // databaseURL: "https://PROJECT_ID.firebaseio.com",
  projectId: "arash-317602",
  storageBucket: "arash-317602.appspot.com",
  // messagingSenderId: "SENDER_ID",
  appId: "1:1074982902849:web:35b1c48b5ffc7deefa7ac0",
  measurementId: "G-7EE9G2XC8V",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

if (process.env.NODE_ENV !== "production") {
  /* eslint-disable-next-line no-restricted-globals */
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(
    "6Lc-xoMbAAAAAPeGTkQi3AuQyUU9wiqeOE7XfMHn"
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

export default appCheck;
