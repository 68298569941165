import React, { Fragment } from "react";
import "./CheckpointCourseScreen.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import osoService from "constants/settings";
import { AuthContext } from "contexts/Context";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button, Link, ListItemIcon } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import refreshToken from "utils/authUtils";
import CodeSnippet from "components/CodeSnippet";
import lockedVideoPoster from "images/lockedVideoPoster.png";
import FilePresentIcon from "@mui/icons-material/FilePresent";

class CheckpointCourseScreen extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    const questions = { ...this.props.topic.questions };
    for (let questionId in questions) {
      questions[questionId]["anchorEls"] = new Array(10).fill(null);
      if (questions[questionId]["user_meta_text_answer"]) {
        questions[questionId]["answerSubmitted"] = true;
      }
    }

    this.state = {
      questions,
      currentTopicId: this.props.currentTopicId,
    };
  }

  async componentDidMount() {
    const questions = { ...this.state.questions };
    for (let questionId in questions) {
      if (questions[questionId].image_src) {
        questions[questionId].image_download_link = await this.getAsset(
          questions[questionId].image_src
        );
      }
      if (questions[questionId].file_src) {
        questions[questionId].file_download_link = await this.getAsset(
          questions[questionId].file_src
        );
      }
    }

    this.setState({
      questions,
    });
  }

  static getDerivedStateFromProps(props, state) {
    // This will update when props change or setState is called
    if (props.currentTopicId === state.currentTopicId) {
      // This will prevent setState to trigger the rest of the function
      return null;
    }
    const questions = { ...props.topic.questions };
    for (let questionId in questions) {
      questions[questionId]["anchorEls"] = new Array(10).fill(null);
      if (questions[questionId]["user_meta_text_answer"]) {
        questions[questionId]["answerSubmitted"] = true;
      }
    }
    return {
      questions,
      // I update currentTopicId so this doesn't get called on setState
      currentTopicId: props.currentTopicId,
    };
  }

  handlePopoverClick = (event, index, questionId) => {
    /* How I'm using multiple popovers:
    I'm assuming there is max of 10 hints
    each hint is assigned in array and mapped to message. */

    let questions = { ...this.state.questions };
    questions[questionId]["anchorEls"][index] = event.currentTarget;
    this.setState({ questions });
  };

  handlePopoverClose = () => {
    let questions = { ...this.state.questions };
    for (let questionId in questions) {
      questions[questionId]["anchorEls"] = new Array(10).fill(null);
    }
    this.setState({
      questions,
    });
  };

  selectedApiHelper = async (selected, questionId, choiceId) => {
    const idToken = await refreshToken(this.context.user);

    fetch(`${osoService}/mark_selected`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //make sure to serialize your JSON body
      body: JSON.stringify({
        email: this.context.user.email,
        topic_id: this.props.currentTopicId,
        question_id: questionId,
        choice_id: choiceId,
        user_meta_selected: selected,
        id_token: idToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .finally(() => {});
  };

  handleChoiceClick(questionId, choiceId) {
    let questions = { ...this.state.questions };
    const selected =
      !questions[questionId]["choices"][choiceId]["user_meta_selected"];
    questions[questionId]["choices"][choiceId]["user_meta_selected"] = selected;
    this.setState({
      questions,
    });
    if (this.context.user.email) {
      this.selectedApiHelper(selected, questionId, choiceId);
    }
  }

  renderAllQuestions(questions) {
    if (Object.entries(questions).length === 0) {
      return <img alt="locked" src={lockedVideoPoster} width="100%" />;
    }
    const sortedQuestions = Object.fromEntries(
      Object.entries(questions).sort()
    );
    const allQuestions = Object.keys(sortedQuestions).map((questionId, i) => (
      <ListItem disablePadding key={this.props.currentTopicId + questionId + i}>
        <ListItemText
          primary={this.renderQuestion(
            questionId,
            sortedQuestions[questionId],
            i + 1
          )}
        />
      </ListItem>
    ));
    return <List className="question-desc">{allQuestions}</List>;
  }

  renderQuestion(questionId, question, questionNumber) {
    return (
      <div>
        <h3 className="question-title">
          {questionNumber}. {this.replaceStringWithElement(question.desc)}
        </h3>
        {this.renderBulletPoints(question)}
        {this.renderHints(questionId, question)}
        {this.renderChoices(questionId, question)}
        {this.renderAnswerBox(questionId, question)}
        {this.renderTask(questionId, question)}
        {this.renderResource(questionId, question)}
      </div>
    );
  }

  replaceStringWithElement(fullString) {
    let elements = [];

    const replaceHelper = (partialString) => {
      if (!partialString) {
        return;
      }

      const tags = {
        "---code-": "-code---",
        "---inlineCode-": "-inlineCode---",
        "---breakLine-": "-breakLine---",
      };

      let minStartIndex = Infinity;
      let startIndex = -1;
      let minStartTag = null;
      let minEndTag = null;

      for (let startTag in tags) {
        startIndex = partialString.indexOf(startTag);

        if (startIndex !== -1 && startIndex < minStartIndex) {
          minStartIndex = startIndex;
          minStartTag = startTag;
          minEndTag = tags[startTag];
        }
      }

      startIndex = minStartIndex;
      let startTag = minStartTag;
      let endTag = minEndTag;

      if (startIndex !== Infinity) {
        const randomString = Math.random().toString(36).slice(2, 7);
        elements.push(
          <span key={startTag + startIndex + "startRawString" + randomString}>
            {partialString.substring(0, startIndex)}
          </span>
        );

        const endIndex = partialString.indexOf(endTag);
        const elementString = partialString
          .substring(startIndex + startTag.length, endIndex)
          .trim();
        if (startTag === "---code-") {
          elements.push(
            <CodeSnippet
              key={startTag + startIndex + randomString}
              codeString={elementString}
            />
          );
        } else if (startTag === "---inlineCode-") {
          const randomString = Math.random().toString(36).slice(2, 7);
          elements.push(
            <CodeSnippet
              key={startTag + startIndex + randomString}
              codeString={elementString}
              display="inline"
            />
          );
        } else if (startTag === "---breakLine-") {
          elements.push(<br key={startTag + startIndex + randomString} />);
        }

        replaceHelper(partialString.substring(endIndex + endTag.length));
      } else {
        let hasNewLine = false;
        for (let snippet of partialString.split("\n")) {
          elements.push(<span key={"textsnippet" + snippet}>{snippet}</span>);
          elements.push(<br key={"break" + snippet} />);
          hasNewLine = true;
        }
        if (hasNewLine) {
          elements.pop();
        }
      }
    };
    replaceHelper(fullString);
    return <span>{elements}</span>;
  }

  renderChoices(questionId, question) {
    const choices = question.choices;
    if (!choices) {
      return;
    }

    const sortedChoices = Object.fromEntries(Object.entries(choices).sort());
    const choiceItem = (questionId, choiceId) => {
      return this.state.questions[questionId] &&
        this.state.questions[questionId]["choices"] &&
        this.state.questions[questionId]["choices"][choiceId] ? (
        <ListItem
          disablePadding
          key={this.props.currentTopicId + questionId + choiceId}
          onClick={() => {
            this.handleChoiceClick(questionId, choiceId);
          }}
        >
          <Paper
            elevation={3}
            className={
              !this.state.questions[questionId]["choices"][choiceId][
                "user_meta_selected"
              ]
                ? "choice-paper"
                : this.state.questions[questionId]["choices"][choiceId][
                    "is_correct"
                  ]
                ? "choice-paper choice-paper-selected-correct"
                : "choice-paper choice-paper-selected-incorrect"
            }
          >
            <ListItemText
              primary={this.replaceStringWithElement(
                sortedChoices[choiceId]["desc"]
              )}
            />
          </Paper>
        </ListItem>
      ) : null;
    };

    const choiceResponseMsg = (questionId, choiceId) =>
      this.state.questions[questionId] &&
      this.state.questions[questionId]["choices"] &&
      this.state.questions[questionId]["choices"][choiceId] ? (
        <ListItem
          disablePadding
          key={`${this.props.currentTopicId + questionId + choiceId}alert`}
          className={
            !this.state.questions[questionId]["choices"][choiceId][
              "user_meta_selected"
            ]
              ? "choice-msg-wrapper-hidden"
              : "choice-msg-wrapper-show"
          }
        >
          <Alert
            severity={
              sortedChoices[choiceId]["is_correct"] ? "success" : "error"
            }
            className="choice-msg"
          >
            {sortedChoices[choiceId]["message"]}
          </Alert>
        </ListItem>
      ) : null;
    const allChoices = Object.keys(sortedChoices).map((choiceId, i) => (
      <Fragment
        key={
          this.props.currentTopicId + questionId + i + choiceId + "allchoice"
        }
      >
        {choiceItem(questionId, choiceId)}
        {choiceResponseMsg(questionId, choiceId)}
      </Fragment>
    ));
    return <List className="desc">{allChoices}</List>;
  }

  handleTextAnswerChange(questionId, e) {
    let questions = { ...this.state.questions };
    questions[questionId]["user_meta_text_answer"] = e.target.value;
    questions[questionId]["answerSubmitted"] = false;
    this.setState({
      questions,
    });
  }

  async handleTextAnswerSubmit(questionId) {
    let questions = { ...this.state.questions };
    questions[questionId]["answerSubmitted"] = true;
    this.setState({
      questions,
    });

    const idToken = await refreshToken(this.context.user);

    fetch(`${osoService}/submit_text_answer`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //make sure to serialize your JSON body
      body: JSON.stringify({
        email: this.context.user.email,
        topic_id: this.props.currentTopicId,
        question_id: questionId,
        id_token: idToken,
        user_meta_text_answer:
          this.state.questions[questionId]["user_meta_text_answer"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {})
      .finally(() => {});
  }

  renderAnswerBox(questionId, question) {
    const disabledSubmit =
      !this.context.userMetadata ||
      Object.keys(this.context.userMetadata).length === 0;

    if (question.type && question.type === "text_answer") {
      const answer = question.user_meta_text_answer;
      return (
        <Fragment>
          <TextareaAutosize
            className="text-answer"
            value={answer || ""}
            onChange={(e) => this.handleTextAnswerChange(questionId, e)}
            key={this.props.currentTopicId + questionId + "text_answer"}
            maxLength="1024"
          />
          <Tooltip title={disabledSubmit ? "Login to track your progress" : ""}>
            <span>
              <Button
                className="text-answer-submit"
                disabled={
                  disabledSubmit ||
                  this.state.questions[questionId]["answerSubmitted"]
                }
                onClick={() => this.handleTextAnswerSubmit(questionId)}
              >
                {this.state.questions[questionId]["answerSubmitted"]
                  ? "Submitted"
                  : "Submit"}
              </Button>
            </span>
          </Tooltip>
        </Fragment>
      );
    }
  }

  async getAsset(assetName) {
    this.setState({
      wait: true,
    });
    // wait to refresh token before calling the api
    const idToken = await refreshToken(this.context.user);

    return fetch(`${osoService}/get_asset`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        video_name: assetName,
        email: this.context.user.email,
        id_token: idToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const asset = data.asset;
        return asset;
      })
      .catch((e) => {
        console(e);
      })
      .finally(() => {
        this.setState({
          wait: true,
        });
      });
  }

  renderTask(questionId, question) {
    if (question.type !== "task") {
      return;
    }
    if (["tags", "list"].includes(question.display_format)) {
      const xs = question.display_format === "tags" ? 3 : 12;
      const tags = question.task_list.map((tag, i) => (
        <Grid item xs={xs} key={this.props.currentTopicId + tag + i + "tag"}>
          <code className="tag">{tag}</code>
        </Grid>
      ));
      return (
        <Box className="tags-container" sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1.5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {tags}
          </Grid>
        </Box>
      );
    } else if (question.image_download_link) {
      return (
        <img
          alt="task"
          className={question.image_src_class}
          src={question.image_download_link}
        />
      );
    } else if (question.file_download_link) {
      return (
        <Link href={question.file_download_link} download={question.file_src}>
          <List>
            <ListItem>
              <ListItemIcon className="file-downlowd-icon">
                <FilePresentIcon />
              </ListItemIcon>
              <ListItemText>{question.file_src}</ListItemText>
            </ListItem>
          </List>
        </Link>
      );
    } else {
      return null;
    }
  }

  renderHints(questionId, question) {
    if (!question.hints) {
      return;
    }

    const opens = this.state.questions[questionId]["anchorEls"];

    return question.hints.map((hint, i) => (
      <Fragment key={this.props.currentTopicId + "hint" + i + questionId}>
        <Button
          aria-describedby={i + questionId}
          size="small"
          variant="text"
          onClick={(e) => this.handlePopoverClick(e, i, questionId)}
        >
          Hint {i + 1}
        </Button>
        <Popover
          id={i + questionId}
          open={Boolean(opens[i])}
          anchorEl={this.state.questions[questionId]["anchorEls"][i]}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <span className="hint-popover">{hint}</span>
        </Popover>
      </Fragment>
    ));
  }

  renderResource(questionId, question) {
    if (question.links) {
      const links = question.links;
      const linksHtml = links.map((link) => (
        <div className="question-link" key={"div" + link}>
          <a target="_blank" rel="noopener noreferrer" href={link} key={link}>
            {link}
          </a>
        </div>
      ));
      return <div>{linksHtml}</div>;
    }
    // keeping this for backward compatibility. Can remove when I replace link with links on server side
    if (question.link) {
      const link = question.link;
      return (
        <div className="question-link">
          <a target="_blank" rel="noopener noreferrer" href={link}>
            {link}
          </a>
        </div>
      );
    }
  }

  renderBulletPoints(question) {
    if (question.bullet_points) {
      const bulletPoints = question.bullet_points;
      const bulletsHtml = bulletPoints.map((bulletPoint) => (
        <li className="bullet-point" key={"bullet" + bulletPoint}>
          {bulletPoint}
        </li>
      ));
      return (
        <div>
          <ul>{bulletsHtml}</ul>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="checkpoint">
        <h1>{this.props.topic.name}</h1>
        {this.renderAllQuestions(this.props.topic.questions)}
      </div>
    );
  }
}

export default CheckpointCourseScreen;
