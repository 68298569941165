import React from "react";
import "./Layout.css";
import Footer from "components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CareerTrack from "components/CareerTrack";
import MenuBar from "components/MenuBar";
import SignUp from "components/SignUp";
import Login from "components/Login";
import {
  AuthContext,
  CartContext,
  UserMetadataContext,
} from "contexts/Context";
import frontendMain from "images/frontend_track.svg";
import Grid from "@mui/material/Grid";
import ForgotPassword from "components/ForgotPassword";
import Checkout from "components/Checkout";
import ScrollToTop from "components/ScrollToTop";
import PrivacyPolicy from "components/PrivacyPolicy";
import TermsOfService from "components/TermsOfService";
import OsoPhilosophy from "components/OsoPhilosophy";
import Landing from "components/Landing";
import Offerings from "components/Offerings";
import AboutUs from "components/AboutUs";
import CareerCoaching from "components/CareerCoaching";
import Python101 from "components/Python101";
import python101Image from "images/python101.png";

// const theme = createTheme({});

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.authSetter = (authenticated, user) => {
      this.setState(() => ({
        authenticated,
        user,
      }));
    };

    this.totalSetter = (total) => {
      this.setState(() => ({
        total,
      }));
    };

    this.userMetadataSetter = (userMetadata) => {
      this.setState(() => ({
        userMetadata,
      }));
    };

    this.state = {
      authenticated: null,
      user: {},
      authSetter: this.authSetter,
      total: 0,
      totalSetter: this.totalSetter,
      userMetadata: {},
      userMetadataSetter: this.userMetadataSetter,
    };
  }

  renderCareerTrack(courseId) {
    if (this.state.authenticated === null) {
      return null;
    }

    if (
      this.state.authenticated === false ||
      (this.state.authenticated === true &&
        Object.keys(this.state.userMetadata).length !== 0)
    ) {
      return (
        <CareerTrack
          userMetadata={this.state.userMetadata}
          courseId={courseId}
        />
      );
    }
  }

  renderCheckout() {
    if (this.state.authenticated === null) {
      return null;
    }

    if (
      this.state.authenticated === false ||
      (this.state.authenticated === true &&
        Object.keys(this.state.userMetadata).length !== 0)
    ) {
      return <Checkout productImage={frontendMain} />;
    }
  }

  renderCareerCoaching() {
    if (this.state.authenticated === null) {
      return null;
    }

    if (
      this.state.authenticated === false ||
      (this.state.authenticated === true &&
        Object.keys(this.state.userMetadata).length !== 0)
    ) {
      return <CareerCoaching />;
    }
  }

  renderPython101() {
    if (this.state.authenticated === null) {
      return null;
    }

    if (
      this.state.authenticated === false ||
      (this.state.authenticated === true &&
        Object.keys(this.state.userMetadata).length !== 0)
    ) {
      return <Python101 productImage={python101Image} />;
    }
  }

  render() {
    return (
      <UserMetadataContext.Provider value={this.state}>
        <Router>
          <ScrollToTop />
          <div className="landing">
            <CartContext.Provider value={this.state}>
              <AuthContext.Provider value={this.state}>
                <MenuBar />
              </AuthContext.Provider>
            </CartContext.Provider>
            <Switch>
              <Route exact path="/">
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                  className="main-grid"
                >
                  {this.state.userMetadata ? (
                    <Landing userMetadata={this.state.userMetadata} />
                  ) : null}
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="all-career-tracks"
                  >
                    {/* {this.state.userMetadata ? (
                      <Grid
                        container
                        spacing={0}
                        direction={{ xs: "column", md: "row" }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <TrackCard
                            title="Frontend Engineer"
                            description="Beginner to Job"
                            backgroundImage={frontend}
                            actions={true}
                            trackId={txt.FE}
                            userMetadata={this.state.userMetadata}
                          />
                        </Grid>
                        <Grid item>
                          <VideoIntro />
                        </Grid>
                      </Grid>
                    ) : null} */}
                  </Grid>
                  {/* <ThemeProvider theme={theme}>
                    <Download />
                  </ThemeProvider> 
                  <ComingSoon />*/}
                </Grid>
                <Offerings />
                <OsoPhilosophy />
                <AboutUs />
                <Footer />
              </Route>
            </Switch>
          </div>
          <Switch>
            <AuthContext.Provider value={this.state}>
              <Route exact path="/careertrack">
                {this.renderCareerTrack("fe")}
              </Route>
              <Route exact path="/courses/python101">
                {this.renderCareerTrack("py101")}
              </Route>
            </AuthContext.Provider>
          </Switch>
          <AuthContext.Provider value={this.state}>
            <Switch>
              <Route exact path="/signup">
                <SignUp />
              </Route>
              <Route exact path="/login">
                <Login location={this.props.location} />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route exact path="/checkout">
                {this.renderCheckout()}
              </Route>
              <Route exact path="/careercoaching">
                {this.renderCareerCoaching()}
              </Route>
              <Route exact path="/python101">
                {this.renderPython101()}
              </Route>
              <Route exact path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route exact path="/terms">
                <TermsOfService />
              </Route>
            </Switch>
          </AuthContext.Provider>
        </Router>
      </UserMetadataContext.Provider>
    );
  }
}

export default Layout;
