import React from "react";
import "./OsoPhilosophy.css";
import Grid from "@mui/material/Grid";

class OsoPhilosophy extends React.Component {
  render() {
    return (
      <div className="oso-philosophy-wrapper">
        <Grid item className="oso-philosophy" container>
          <Grid item xs={9} className="oso-philosophy-text">
            <h2>What does OSO mean?</h2>
            <div>
              An OSO is a PRACTICAL expert. Someone who gained mastery of a
              discipline through action.
            </div>
            <div>
              The oso.engineer philosophy is to learn by building. Do what you
              would do on the job. No fluff, no extra theories that you'd never
              use in the real world.
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default OsoPhilosophy;
