import React from "react";
import Grid from "@mui/material/Grid";
import "./TermsOfService.css";
import { Link } from "react-router-dom";

class TermsOfService extends React.Component {
  render() {
    return (
      <Grid
        container
        rowSpacing={1}
        direction="column"
        className="policy-container"
      >
        <Grid item xs={4} className="main-policy-title">
          Terms of Service
        </Grid>
        <Grid item xs={4} className="policy-body">
          You know how on a shampoo bottle, it says "don't drink this! and if
          you do, it's not our responsibility." This page is something like
          that. Here it goes; Your use of this website implies acceptance of
          this its terms of service. If you do not agree with all terms, please
          don't use the website. In this document "You" refers to any user of
          this website, and us/our/we refer to OSO Engineer, the website and its
          owners.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Using this Website
        </Grid>
        <Grid item xs={4} className="policy-body">
          By using the website, you imply that you're of legal age and have
          understood this website's terms, consent to them, and perform
          activities that are legal and suitable for you. All the information
          you provide must be true. You will not use the website through bots or
          automated scripts. We reserve the right to suspend your account or
          refuse service if any of the conditions of the terms of service are
          not met.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Intellectual Property Rights
        </Grid>
        <Grid item xs={4} className="policy-body">
          The contents of this website are our intellectual property and unless
          with explicit permission, they are not to be reused, shared, or
          modified.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Registration
        </Grid>
        <Grid item xs={4} className="policy-body">
          You may provide information to register on this website. Please see
          our <Link to="/privacy-policy">Privacy Policy</Link> for more
          information on how we treat your data.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Prohibited Activities
        </Grid>
        <Grid item xs={4} className="policy-body">
          You are not allowed to use this website for any reason other than what
          it's intended for. You may not retrieve information, videos, or other
          educational content without our explicit permission. You may not use
          this website to compete with us. You may not use automated means such
          as scripting or bots to interact with this website. You may not sell
          or share you account.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Contributions
        </Grid>
        <Grid item xs={4} className="policy-body">
          We value your feedback and try to improve our website and service by
          listening to you. You imply that you understand you will not be
          compensated for this contribution unless otherwise stated in separate
          communications directly from us.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Refund Policy
        </Grid>
        <Grid item xs={4} className="policy-body">
          There are no refunds or returns for the products offered through this
          website. Please use the free sections to evaluate if a product is
          right for you before making a purchase.
        </Grid>
        <Grid item xs={4} className="policy-title">
          How do I delete my account
        </Grid>
        <Grid item xs={4} className="policy-body">
          You can delete your account or unsubscribe by contacting
          arash@oso.engineer. I'm the only person working here :) I will add a
          feature soon, so you can delete with a button. Hopefully not a popular
          feature.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Security of your information
        </Grid>
        <Grid item xs={4} className="policy-body">
          We use TLS when transmitting any information. We use reputable third
          party services such as Google and Stripe to handle payments and
          authentication.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Age
        </Grid>
        <Grid item xs={4} className="policy-body">
          No information is knowingly collected for children under the age of
          18.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Changes to Terms of Service
        </Grid>
        <Grid item xs={4} className="policy-body">
          We're a growing website and know very little about law :) So, we
          reserve the rights to modify this policy without notice and changes go
          into effect immediately.
        </Grid>
      </Grid>
    );
  }
}

export default TermsOfService;
