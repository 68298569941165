import React from "react";
import "./CourseCard.css";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";

class CourseCard extends React.Component {
  render() {
    return (
      <Link
        to={this.props.link || ""}
        className={`${this.props.cardLinkClass} card-link`}
      >
        <div
          className="course-card"
          style={{ backgroundImage: `url(${this.props.image})` }}
        >
          <span className="card-text">{this.props.mainText}</span>
          <span className="card-guide">{this.props.footNote}</span>
          <Chip
            label={this.props.chipText}
            size="small"
            variant="filled"
            className={`${this.props.chipClass} chip`}
            // color={this.props.chipColor || "secondary"}
            style={{ backgroundColor: this.props.chipColor }}
          />
        </div>
      </Link>
    );
  }
}

export default CourseCard;
