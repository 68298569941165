const refreshToken = (user) => {
    if (user == null || Object.keys(user).length === 0) {
        return null
    }
    return user
      .getIdToken(/* forceRefresh */ false)
      .then((idToken) => {
        return idToken;
      })
      .catch(function (error) {
        console.error(error);
      });
  };


  export default refreshToken;