import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import materialDark from "react-syntax-highlighter/dist/esm/styles/prism/material-dark";

const CodeSnippet = (props) => {
  return (
    <SyntaxHighlighter
      language="jsx"
      style={materialDark}
      customStyle={{
        display: props.display || "block",
        padding: "5px",
        margin: "2px",
        fontWeight: "100",
        fontSize: "17px",
        borderRadius: "3px",
      }}
    >
      {props.codeString}
    </SyntaxHighlighter>
  );
};

export default CodeSnippet;
