import React from "react";
import "./TrackCard.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { AuthContext } from "contexts/Context";
import txt from "constants/text";

class TrackCard extends React.Component {
  static contextType = AuthContext;

  renderActions = () => {
    const is_enrolled =
      this.props.userMetadata &&
      this.props.userMetadata[txt.FE] &&
      this.props.userMetadata[txt.FE][
        txt.USER_META_.concat(txt.ENROLLMENT_STATUS)
      ] === txt.ENROLLED
        ? true
        : false;
    if (this.props.actions) {
      if (is_enrolled) {
        return (
          <CardActions className="card-actions">
            <Button
              component={Link}
              variant="contained"
              color="success"
              to="/careertrack"
            >
              Continue
            </Button>
          </CardActions>
        );
      } else {
        return (
          <CardActions className="card-actions">
            <Button
              variant="contained"
              color="success"
              component={Link}
              to="/checkout"
              className="preorder-button"
            >
              Enroll
            </Button>
            <Button
              component={Link}
              to="/careertrack"
              className="peek-inside-button"
            >
              Peek Inside
            </Button>
          </CardActions>
        );
      }
    } else {
      return <div className="action-alt">Under Construction</div>;
    }
  };

  handleCardClick = () => {
    const is_enrolled =
      this.props.userMetadata &&
      this.props.userMetadata[txt.FE] &&
      this.props.userMetadata[txt.FE][
        txt.USER_META_.concat(txt.ENROLLMENT_STATUS)
      ] === txt.ENROLLED
        ? true
        : false;
    if (this.props.trackId === txt.FE) {
      if (is_enrolled) {
        this.props.history.push("/careertrack");
      } else {
        this.props.history.push("/checkout");
      }
    }
  };

  render() {
    return (
      <Card
        raised={true}
        sx={{
          width: 320,
          boxShadow: 1,
          margin: 2,
        }}
        className={this.props.trackId === txt.FE ? "track-card" : ""}
      >
        <div className="card-circle animated-card"></div>
        <div className="card-circle-pattern"></div>
        <div className="card-circle-2"></div>
        <div className="card-circle-pattern-2"></div>
        <div className="card-square animated-card"></div>
        <div className="card-square-pattern"></div>
        <CardContent
          className="card-main"
          onClick={() => this.handleCardClick()}
        >
          <div className="card-main-text">
            <div>FRONTEND</div>
            <div>ENGINEER</div>
          </div>
        </CardContent>
        <CardContent
          className="card-ribbon"
          onClick={() => this.handleCardClick()}
        >
          <div className="ribbon-text">
            <Typography variant="body2">New Career Track</Typography>
            <Typography gutterBottom variant="h5" component="div">
              Available Now
            </Typography>
          </div>
        </CardContent>
        {this.renderActions()}
      </Card>
    );
  }
}

export default withRouter(TrackCard);
