import { Component, Fragment } from "react";
import Grid from "@mui/material/Grid";
import osoService from "constants/settings";
import "./Checkout.css";
import "./Python101.css";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Typography from "@mui/material/Typography";
import ConstructionIcon from "@mui/icons-material/Construction";
import SettingsIcon from "@mui/icons-material/Settings";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import ComputerIcon from "@mui/icons-material/Computer";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import myface from "../images/me.jpg";
// import heather from "../images/heather.jpg";
import { AuthContext } from "contexts/Context";
import txt from "constants/text";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
// import Instructor from "components/Instructor";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import PublicIcon from "@mui/icons-material/Public";
import WorkIcon from "@mui/icons-material/Work";
import refreshToken from "utils/authUtils";
import SchoolIcon from "@mui/icons-material/School";
import ChatIcon from "@mui/icons-material/Chat";
// import VideoIntro from "components/VideoIntro";
// import CountDown from "components/CountDown";

class Python101 extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      idToken: null,
    };
  }

  async componentDidMount() {
    const idToken = await refreshToken(this.context.user);
    this.setState({
      idToken,
    });
  }

  renderBuyButton = (selectedProduct, buttonClass) => {
    const isLoggedIn = async (event) => {
      if (this.context.authenticated) {
        // Pass (action will happen)
      } else {
        event.preventDefault();
        const prevUrl = window.location.pathname;
        this.props.history.push({
          pathname: `/login`,
          state: {
            event: txt.MUST_LOGIN_TO_ENROLL,
          },
          prevUrl,
        });
      }
    };

    let btnTxt = (
      <span>
        Pre Order &nbsp;<span className="price-249">&nbsp;$19&nbsp;</span>{" "}
      </span>
    );

    // TODO: Tie selected product to the appropriate product
    return (
      <form
        onSubmit={isLoggedIn}
        action={`${osoService}/create-checkout-session?email=${this.context.user.email}&id_token=${this.state.idToken}&selected_product=${selectedProduct}`}
        method="POST"
      >
        <Button
          className={`buy-button ${buttonClass}`}
          type="submit"
          variant="contained"
          color="success"
          size="large"
        >
          {btnTxt}
        </Button>
      </form>
    );
  };

  getListIcon = (text) => (
    <ListItem className="career-coaching-bullet-item">
      <ListItemIcon className="career-coaching-bullets-icon">
        <CheckCircleIcon sx={{ color: "green", fontSize: 20 }} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "14px", lineHeight: "18px" }}
        primary={text}
      />
    </ListItem>
  );

  render() {
    const peekInsideButton = (
      <Button
        component={Link}
        to="/courses/python101"
        className="peek-inside-button-checkout"
      >
        Peek Inside
      </Button>
    );

    const whatYouLearnItems = [
      {
        title: "The Power of Python",
        desc: `It's always good to know why you should learn something. You might also be wondering what types of jobs or projects use Python.`,
        icon: <SchoolIcon className="syl-icon" />,
      },
      {
        title: "First Lines of Code",
        desc: `We will jump start by writing some code together so you see Python doesn't bite. Then we'll slowly go over different fundamental concepts.`,
        icon: <ConstructionIcon className="syl-icon" />,
      },
      {
        title: "Discord Community",
        desc: `Community of learners and instructors so we can help each other.`,
        icon: <ChatIcon className="syl-icon" />,
      },
      {
        title: "Project",
        desc: `Learning through building is fun and engaging. You'll build a project to solidify what you learn throughout this course.`,
        icon: <PublicIcon className="syl-icon" />,
      },
    ];

    const whatYouWillLearn = whatYouLearnItems.map((item) => (
      <ListItem
        className="list-what-you-learn"
        key={item.title}
        alignItems="flex-start"
      >
        <Divider className="divi" orientation="vertical" flexItem />
        <ListItemIcon className="syl-icon-wrapper">{item.icon}</ListItemIcon>

        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            style: { whiteSpace: "normal" },
            variant: "h6",
            component: "div",
            gutterBottom: true,
          }}
          secondary={item.desc}
          secondaryTypographyProps={{
            variant: "body1",
            component: "div",
            gutterBottom: true,
          }}
        />
      </ListItem>
    ));

    const whatYouLearn = (
      <Grid
        className="what-you-learn"
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={9}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            className="what-you-learn-title"
          >
            Program Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <Paper elevation={3} className="what-you-learn-paper">
            <List>
              {whatYouWillLearn}
              <ListItem
                className="list-what-you-learn"
                key="readiness"
                alignItems="flex-start"
              >
                <ListItemIcon className="flag-icon-wrapper">
                  <SportsScoreIcon className="flag-icon" />
                </ListItemIcon>

                <ListItemText
                  primary="Understanding of Python Basics"
                  primaryTypographyProps={{
                    style: { whiteSpace: "normal" },
                    variant: "h6",
                    component: "div",
                    gutterBottom: true,
                  }}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    );

    // const yourInstructors = (
    //   <Fragment>
    //     <Typography
    //       className="instructor-title"
    //       variant="h4"
    //       component="div"
    //       gutterBottom
    //     >
    //       Your Instructors
    //     </Typography>
    //     <Grid
    //       container
    //       direction="row"
    //       justifyContent="space-between"
    //       alignItems="flex-start"
    //       className="instructors-container"
    //       spacing={2}
    //     >
    //       <Grid item md={6} xs={12}>
    //         <Instructor image={myface} blurb={txt.ARASH_INSTRUCTOR} />
    //       </Grid>
    //       <Grid item md={6} xs={12}>
    //         <Instructor image={heather} blurb={txt.HEATHER_INSTRUCTOR} />
    //       </Grid>
    //     </Grid>
    //   </Fragment>
    // );

    const continueButton = (
      <Button
        component={Link}
        variant="contained"
        color="success"
        to="/courses/python101"
        className="continue-button"
      >
        Continue
      </Button>
    );

    const loadButtons = (buttons) => {
      if (
        this.context.userMetadata &&
        this.context.userMetadata["py101"] &&
        this.context.userMetadata["py101"]["user_meta_enrollment_status"] ===
          txt.ENROLLED
      ) {
        return continueButton;
      }
      if (buttons === "BOTH") {
        return (
          <Fragment>
            {this.renderBuyButton("PYTHON_101", "fat-green-button")}{" "}
            {peekInsideButton}
          </Fragment>
        );
      } else {
        return this.renderBuyButton("PYTHON_101", "fat-green-button");
      }
    };

    const grid = (
      <Fragment>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="checkout-container"
        >
          <Grid className="product-img-container" item xs={12}>
            <img
              className="product-img"
              src={this.props.productImage}
              alt="career track"
            />
          </Grid>
          <Grid
            container
            sx={{
              flexDirection: { xs: "column", md: "row" },
              width: { md: "100%" },
              minWidth: { md: "900px" },
            }}
            justifyContent="center"
            alignItems="center"
            className="but-peek-buttons-container"
          >
            {loadButtons("BOTH")}
          </Grid>
          <div className="reduced-price">
            Lifetime Access
            <div className="preorder-warning">
              You can preorder to watch the new vidoes as I release the
              chapters. I'm currently actively making this course.
            </div>
            <div> {/* <CountDown /> */}</div>
          </div>
          <Grid
            className="product-desc"
            container
            sx={{
              flexDirection: { xs: "column", md: "row" },
              width: { md: "75%", xs: "90%" },
              minWidth: { md: "900px" },
              marginLeft: { xs: "15px" },
            }}
            justifyContent="space-around"
          >
            <Grid item xs={12} md={3}>
              <Paper className="product-paper" elevation={0}>
                <Typography variant="h6" component="div" gutterBottom>
                  Prerequisites
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  A computer :) <br />
                </Typography>
              </Paper>
            </Grid>
            <Divider orientation="vertical" flexItem>
              <ArrowRightAltIcon
                className="arrow"
                sx={{ display: { md: "inline", xs: "none" } }}
              />
              <KeyboardArrowDownIcon
                className="arrow"
                sx={{ display: { md: "none", xs: "inline" } }}
              />
            </Divider>
            <Grid item xs={12} md={3}>
              <Paper className="product-paper" elevation={0}>
                <Typography variant="h6" component="div" gutterBottom>
                  Estimated Time to Finish
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  1-4 weeks <br />
                </Typography>
              </Paper>
            </Grid>
            <Divider orientation="vertical" flexItem>
              <ArrowRightAltIcon
                className="arrow"
                sx={{ display: { md: "inline", xs: "none" } }}
              />
              <KeyboardArrowDownIcon
                className="arrow"
                sx={{ display: { md: "none", xs: "inline" } }}
              />
            </Divider>
            <Grid item xs={12} md={3}>
              <Paper className="product-paper" elevation={0}>
                <Typography variant="h6" component="div" gutterBottom>
                  Expected Results
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  Familiarity with Python fundamentals.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          {/* <VideoIntro /> */}

          {whatYouLearn}
          {/* {yourInstructors} */}
          <Grid item xs={12} className="bottom-enroll-button">
            {loadButtons("ONE")}
          </Grid>
        </Grid>
      </Fragment>
    );

    return grid;

    // return message ? message : productDisplay;
  }
}

export default withRouter(Python101);
