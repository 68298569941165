import React from "react";
import "./MenuBar.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import Button from "@mui/material/Button";
import osoService from "constants/settings";
import Typography from "@mui/material/Typography";
import Logo from "components/Logo";
import DropDown from "components/DropDown";
import HamburgerMenu from "components/HamburgerMenu";
import { AuthContext } from "contexts/Context";
import { withRouter } from "react-router-dom";

const auth = getAuth();

class MenuBar extends React.Component {
  static contextType = AuthContext;

  componentDidMount() {
    this.checkAuth();
    // this.context.authSetter();
    // this.context.totalSetter(100);
  }

  callToken = (id_token) => {
    fetch(`${osoService}/verify_token`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        id_token: id_token,
      }),
    })
      .then((response) => {
        response.json();
      })
      .catch((error) => {
        // const errorCode = error.code;
        console.error(error);
      })
      .finally(() => {});
  };

  getUserMetadata = (user) => {
    user
      .getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        fetch(`${osoService}/get_user_metadata`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },

          //make sure to serialize your JSON body
          body: JSON.stringify({
            email: user.email,
            id_token: idToken,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data && data.message && data.message.courses) {
              this.context.userMetadataSetter(data.message.courses);
            } else {
              // addCourseToUser
            }
          })
          .finally(() => {});
        // // Send token to your backend via HTTPS
        // this.callToken(idToken);
        // this.context.authSetter(true, user, idToken);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  checkAuth = () => {
    onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        user
          .getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            // Send token to your backend via HTTPS
            this.callToken(idToken);
            this.context.authSetter(true, user);
          })
          .catch(function (error) {
            console.error(error);
          });

        // const uid = user.uid;

        this.getUserMetadata(user);
      } else {
        // User is signed out
        this.context.authSetter(false, {});
        // ...
      }
    });
  };

  handleLogout = () => {
    signOut(auth);
    this.context.userMetadataSetter({});
    // clear query params. For instance when a paid customer on an ENROLLED tier chapter logs out,
    // lesson is wiped so the page goes to first chapter which should be free and supported.
    this.props.history.push({
      search: "?" + new URLSearchParams({}).toString(),
    });
  };

  handleClick = () => {
    const prevUrl = window.location.pathname;
    this.props.history.push({
      pathname: `/login`,
      prevUrl,
    });
  };

  renderLoginButton = (whiteButtons) => {
    // in case of singedIn=null(before user data is fetched) no login or logout button is returned
    if (this.context.authenticated === false) {
      return (
        <Button
          className={`${whiteButtons} login-button`}
          onClick={this.handleClick}
        >
          Login
        </Button>
      );
    } else if (this.context.authenticated === true) {
      return (
        <React.Fragment>
          <Typography variant="caption" className="user-account">
            Logged in as {this.context.user.email}
          </Typography>
          <Button
            className={`${whiteButtons} logout-button`}
            onClick={() => this.handleLogout()}
          >
            Logout
          </Button>
        </React.Fragment>
      );
    }
  };
  renderSignupButton = (whiteButtons) => {
    // in case of singedIn=null(before user data is fetched) no login or logout button is returned
    if (this.context.authenticated === false) {
      return (
        <Button
          className={`${whiteButtons} signup-button`}
          component={Link}
          to="/signup"
        >
          Sign Up
        </Button>
      );
    }
  };
  render() {
    const currentUrl = window.location.href;
    let menuBarClass = null;
    let whiteButtons = null;
    if (currentUrl.includes("careertrack")) {
      menuBarClass = "black-menu";
      whiteButtons = "white-text";
    } else {
      menuBarClass = "white-menu";
    }

    // if (menuBarClass === "black-menu") {
    //   whiteButtons = "white-text";
    // }

    return (
      <AppBar position="sticky" className={`${menuBarClass}  menu-bar`}>
        <Toolbar>
          {/* <AuthContext.Consumer>
            {({ authenticated, authSetter }) => (
              <button onClick={() => authSetter(true)}>Toggle Auth</button>
            )}
          </AuthContext.Consumer>
          <CartContext.Consumer>{({ total }) => total}</CartContext.Consumer> */}
          <HamburgerMenu buttonClass={whiteButtons} />
          <Logo />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              display: { xs: "none", lg: "flex" },
            }}
          >
            <DropDown
              dropDownTitle="Career Tracks"
              dropDownItems={[{ name: "Frontend Engineer", link: "/checkout" }]}
              key="Career Tracks"
              buttonClass={whiteButtons}
            />
            <DropDown
              dropDownTitle="Courses"
              dropDownItems={[
                {
                  name: "Python for Beginners",
                  link: "/python101",
                  disabled: true,
                },
              ]}
              key="Courses"
              buttonClass={whiteButtons}
            />
            <DropDown
              dropDownTitle="Coaching"
              dropDownItems={[
                { name: "1 on 1", link: "careercoaching" },
                { name: "Break Into Tech", link: "careercoaching" },
                { name: "Top Tech Plan", link: "careercoaching" },
              ]}
              key="Coaching"
              buttonClass={whiteButtons}
            />
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {this.renderLoginButton(whiteButtons)}{" "}
            <span hidden={this.context.authenticated === true} className="pipe">
              |
            </span>{" "}
            {this.renderSignupButton(whiteButtons)}
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(MenuBar);
