import { Button, Grid } from "@mui/material";
import React, { Component } from "react";
import "./CareerCoaching.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CareerCoachingFAQ from "components/CareerCoachingFAQ";
import Footer from "components/Footer";
import txt from "constants/text";
import osoService from "constants/settings";
import { AuthContext } from "contexts/Context";
import refreshToken from "utils/authUtils";
import { withRouter } from "react-router-dom";
import { logError } from "utils/log_utils";

class CareerCoaching extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      idToken: null,
      videoSrc: null,
    };
  }

  async componentDidMount() {
    const idToken = await refreshToken(this.context.user);
    this.setState({
      idToken,
    });
    this.getVideo("careercoaching.mp4");
  }

  getListIcon = (text) => (
    <ListItem className="career-coaching-bullet-item">
      <ListItemIcon className="career-coaching-bullets-icon">
        <CheckCircleIcon sx={{ color: "green", fontSize: 20 }} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "14px", lineHeight: "18px" }}
        primary={text}
      />
    </ListItem>
  );

  renderBuyButton = (selectedProduct, buttonClass) => {
    const isLoggedIn = async (event) => {
      if (this.context.authenticated) {
        // Pass (action will happen)
      } else {
        event.preventDefault();
        const prevUrl = window.location.pathname;
        this.props.history.push({
          pathname: `/login`,
          state: {
            event: txt.MUST_LOGIN_TO_PURCHASE,
          },
          prevUrl,
        });
      }
    };

    return (
      <form
        onSubmit={isLoggedIn}
        action={`${osoService}/create-checkout-session?email=${this.context.user.email}&id_token=${this.state.idToken}&selected_product=${selectedProduct}`}
        method="POST"
      >
        <Button
          variant="outlined"
          className={`career-coach-purchase-button career-coach-purchase-button-${buttonClass}`}
          type="submit"
        >
          Purchase
        </Button>
      </form>
    );
  };

  async getVideo(videoName) {
    this.setState({
      wait: true,
    });
    // wait to refresh token before calling the api

    fetch(`${osoService}/get_video`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({
        video_name: videoName,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const video = data.video;
        const captions = data.captions;
        this.setState({
          videoSrc: video,
          captionsSrc: captions,
        });
      })
      .catch((e) => {
        logError(e);
      })
      .finally(() => {
        this.setState({
          wait: true,
        });
      });
  }

  renderVideo = () => {
    if (this.state.videoSrc) {
      return (
        <div className="career-coaching-vid">
          <video
            width="60%"
            className="career-coaching-vid-element"
            controls
            controlsList="nodownload"
            crossOrigin="ananymous"
            //   poster={stopImage}
          >
            <source src={this.state.videoSrc} type="video/mp4"></source>
          </video>
        </div>
      );
    } else {
      <video width="400" controls controlsList="nodownload">
        <source src="" type="video/mp4"></source>
      </video>;
    }
  };

  render() {
    return (
      <div className="career-coaching-wrapper">
        <div className="career-coaching-background"></div>
        <div className="career-coaching-page-title">Career Coaching</div>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          direction={{ sm: "column", md: "row" }}
          spacing={{ sm: 5, md: 2 }}
          //   alignItems="center"
          className="career-coaching-container"
        >
          <Grid item sm={1} className="career-coaching-gutter"></Grid>
          <Grid item xs={2} className="career-coaching-card single-session">
            <div className="career-coaching-card-title career-coaching-card-title-single">
              <div className="career-coaching-card-title-name">1 on 1</div>
              <div className="career-coaching-card-title-subinfo">
                Single Session (1hr)
              </div>
              <div className="career-coaching-card-title-cost">$149</div>
            </div>

            <div className="career-coaching-card-desc career-coaching-card-desc-single">
              <div className="career-coaching-card-desc-text">
                <div>
                  <List className="career-coaching-bullets">
                    {this.getListIcon("Topic of Your Choice")}
                    {this.getListIcon("Resume Review")}
                    {this.getListIcon("Career Guidance")}
                    {this.getListIcon("Interview Practice")}
                    {this.getListIcon("Compensation Negotiation")}
                    {this.getListIcon("System Design Consultation")}
                  </List>
                </div>
              </div>
              {this.renderBuyButton("ONE_ON_ONE_SINGLE", "single")}
            </div>
          </Grid>
          <Grid item xs={2} className="career-coaching-card ten-session">
            <div className="career-coaching-card-title career-coaching-card-title-break-into">
              <div className="career-coaching-card-title-name">
                Break Into Tech
              </div>
              <div className="career-coaching-card-title-subinfo">
                10 Sessions <br /> + <br /> Frontend Engineer Track
              </div>
              <div className="career-coaching-card-title-cost">$1000</div>
            </div>
            <div className="career-coaching-card-desc-break-into">
              <div className="career-coaching-card-desc-text">
                <div>
                  <List className="career-coaching-bullets">
                    {this.getListIcon("Initial Personal Assessment")}
                    {this.getListIcon("Personalized Study Plan")}
                    {this.getListIcon("Frontend Track Included ($249)")}
                    {this.getListIcon("Recurring Checkups")}
                    {this.getListIcon("Resume Review")}
                    {this.getListIcon("1 on 1 Interview Practice")}
                    {this.getListIcon("Detailed Personal Feedback")}
                  </List>
                </div>
              </div>
              {this.renderBuyButton("BREAK_INTO_TECH_PLAN", "break-into")}
            </div>
          </Grid>
          <Grid item xs={2} className="career-coaching-card twenty-session">
            <div className="career-coaching-card-title career-coaching-card-title-top-tech">
              <div className="career-coaching-card-title-name">
                Top Tech Plan
              </div>
              <div className="career-coaching-card-title-subinfo">
                20 Sessions <br /> + <br /> Frontend Engineer Track
              </div>
              <div className="career-coaching-card-title-cost">$1800</div>
            </div>

            <div className="career-coaching-card-desc-top-tech">
              <div className="career-coaching-card-desc-text">
                <div>
                  <List className="career-coaching-bullets">
                    {this.getListIcon("Initial Personal Assessment")}
                    {this.getListIcon("Personalized Study Plan")}
                    {this.getListIcon("Frontend Track Included ($249)")}
                    {this.getListIcon("Recurring Checkups")}
                    {this.getListIcon("Resume Review")}
                    {this.getListIcon("Behavioral Interview Coaching")}
                    {this.getListIcon("Data Structure & Algorithms Coaching")}
                    {this.getListIcon("System Design Coaching")}
                    {this.getListIcon("1 on 1 Interview Practice")}
                    {this.getListIcon("Detailed Personal Feedback")}
                  </List>
                </div>
              </div>
              {this.renderBuyButton("TOP_TECH_PLAN", "top-tech")}
            </div>
          </Grid>
          <Grid item sm={1} className="career-coaching-gutter"></Grid>
        </Grid>
        {this.renderVideo()}
        <CareerCoachingFAQ />
        <Footer />
      </div>
    );
  }
}

export default withRouter(CareerCoaching);
