import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

class Footer extends React.Component {
  render() {
    return (
      <Grid
        container
        columnSpacing={1}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className="footer"
        align="center"
      >
        <Grid item xs={2}>
          <Link to="/terms" className="footer-link">
            Terms of Service
          </Link>
        </Grid>
        <Grid item xs={7} sm="auto">
          Created by: Arash Rowshan, your friendly neighborhood software
          engineer
        </Grid>
        <Grid item xs={2}>
          <Link to="/privacy-policy" className="footer-link">
            Privacy Policy
          </Link>
        </Grid>
      </Grid>
    );
  }
}

export default Footer;
