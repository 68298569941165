import React from "react";
// import YoutubeEmbed from "components/YoutubeEmbed";
import "./VideoCourseScreen.css";
import CourseVideo from "./CourseVideo";

class VideoCourseScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div key={this.props.topic.name} className="youtube-embed">
        <h1 className="video-title">{this.props.topic.name}</h1>
        {/* <YoutubeEmbed videoEmbedId={this.props.topic.video} /> */}
        <CourseVideo videoName={this.props.topic.video} />
      </div>
    );
  }
}

export default VideoCourseScreen;
