import React, { Component, Fragment } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./CareerCoachingFAQ.css";

class CareerCoachingFAQ extends Component {
  getQuestionAnswer(question, answer) {
    return (
      <Fragment>
        <Accordion className="faq-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="faq-accordion-question">
              {question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="faq-accordion-answer">{answer}</Typography>
          </AccordionDetails>
        </Accordion>
      </Fragment>
    );
  }
  render() {
    return (
      <div className="career-coaching-faq">
        <div className="career-coaching-faq-title">
          Frequently Asked Questions
        </div>
        <div>
          {this.getQuestionAnswer(
            "What happens after I purchase a Single Session?",
            "You will receive an email regarding the next steps within 24 hours. You will have a chance to give a brief description of what you would like to discuss before our session so we can make sure it is a fruitful discussion."
          )}
          {this.getQuestionAnswer(
            "How long will I have access to the 10-session Break Into Tech Plan?",
            `You will have 1 year of access to the Frontend Career Track and up to 2 years of time to use the 10 sessions. 
          The goal is to keep you accountable to make progress without rushing the learning journey.`
          )}
          {this.getQuestionAnswer(
            "How long will I have access to the 20-session Top Tech Plan?",
            `You will have 1 year of access to the Frontend Career Track and up to 3 years of time to use the 20 sessions. 
          The goal is to keep you accountable to make progress without rushing the learning journey.`
          )}
          {this.getQuestionAnswer(
            "What are the prerequisites for the Break Into Tech program?",
            `Only your desire for success. This is a beginner friendly program and no prior coding background is needed.`
          )}
          {this.getQuestionAnswer(
            "Who is the Top Tech Plan for?",
            `If you are in your early to mid career, and aim to work for top tech companies, this programs fits you best.`
          )}
        </div>
      </div>
    );
  }
}

export default CareerCoachingFAQ;
