import React from "react";

export const AuthContext = React.createContext({
  authenticated: false,
  user: {},
  authSetter: () => {},
});

export const UserMetadataContext = React.createContext({
  userMetadata: {},
  userMetadataSetter: () => {},
});


export const CartContext = React.createContext({
  total: 0,
  totalSetter: () => {},
});
