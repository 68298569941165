import React from "react";
import "./Offerings.css";
import Grid from "@mui/material/Grid";
import CourseCard from "components/CourseCard";
import careerTrackImage from "images/html-color.jpg";
import redBackground from "images/red-background.png";
import greenBackground from "images/green-background.png";

class Offerings extends React.Component {
  render() {
    return (
      <div className="offerings-wrapper">
        <div className="offerings-title">Services & Course Offerings</div>
        <Grid
          className="offerings-container"
          container
          justifyContent="center"
          alignItems="center"
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 5, sm: 0 }}
        >
          <Grid item xs={3}>
            <CourseCard
              image={careerTrackImage}
              mainText="Career Tracks"
              footNote="Prepare for a specific job."
              chipText={"1 Track Available"}
              chipColor="darkblue"
              link="/checkout"
              chipClass="click-chip"
              cardLinkClass="clickable-card"
            />
          </Grid>
          <Grid item xs={3}>
            <CourseCard
              image={greenBackground}
              mainText={`Career Coaching`}
              footNote={`Personalized plan for you.`}
              chipText={"3 Plans Available"}
              link="/careercoaching"
              chipColor="darkblue"
              chipClass="click-chip"
              cardLinkClass="clickable-card"
            />
          </Grid>
          <Grid item xs={3}>
            <CourseCard
              image={redBackground}
              mainText="Courses"
              footNote={`Learn a specific topic.`}
              // link="/python101"
              chipText="Under Construction"
              chipColor="gray"
              chipClass="disabled-chip"
              cardLinkClass="clickable-card"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Offerings;
