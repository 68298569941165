import React from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import Layout from "./components/Layout";
import { getCookie, setCookie } from "./utils/cookie";
import { v4 as uuidv4 } from "uuid";

function App() {
  if (getCookie("randomId") === "") {
    const randomId = uuidv4();
    setCookie("randomId", randomId, 365);
  }

  return (
    <div className="app">
      <Layout />
    </div>
  );
}

export default App;
