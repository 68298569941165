import { Component, Fragment } from "react";
import Grid from "@mui/material/Grid";
import osoService from "constants/settings";
import "./Checkout.css";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Typography from "@mui/material/Typography";
import ConstructionIcon from "@mui/icons-material/Construction";
import SettingsIcon from "@mui/icons-material/Settings";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import ComputerIcon from "@mui/icons-material/Computer";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import myface from "../images/me.jpg";
// import heather from "../images/heather.jpg";
import { AuthContext } from "contexts/Context";
import txt from "constants/text";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
// import Instructor from "components/Instructor";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import PublicIcon from "@mui/icons-material/Public";
import WorkIcon from "@mui/icons-material/Work";
import refreshToken from "utils/authUtils";
import SchoolIcon from "@mui/icons-material/School";
import ChatIcon from "@mui/icons-material/Chat";
// import VideoIntro from "components/VideoIntro";
// import CountDown from "components/CountDown";

class Checkout extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      idToken: null,
    };
  }

  async componentDidMount() {
    const idToken = await refreshToken(this.context.user);
    this.setState({
      idToken,
    });
  }

  renderBuyButton = (selectedProduct, buttonClass) => {
    const isLoggedIn = async (event) => {
      if (this.context.authenticated) {
        // Pass (action will happen)
      } else {
        event.preventDefault();
        const prevUrl = window.location.pathname;
        this.props.history.push({
          pathname: `/login`,
          state: {
            event: txt.MUST_LOGIN_TO_ENROLL,
          },
          prevUrl,
        });
      }
    };

    let btnTxt = (
      <span>
        Enroll Now &nbsp;<span className="price-249">&nbsp;$249&nbsp;</span>{" "}
      </span>
    );

    if (buttonClass === "course-only") {
      btnTxt = <span>Enroll Now</span>;
    }

    if (buttonClass === "extra") {
      btnTxt = <span>Enroll Now</span>;
    }

    // TODO: Tie selected product to the appropriate product
    return (
      <form
        onSubmit={isLoggedIn}
        action={`${osoService}/create-checkout-session?email=${this.context.user.email}&id_token=${this.state.idToken}&selected_product=${selectedProduct}`}
        method="POST"
      >
        <Button
          className={`buy-button ${buttonClass}`}
          type="submit"
          variant="contained"
          color="success"
          size="large"
        >
          {btnTxt}
        </Button>
      </form>
    );
  };

  getListIcon = (text) => (
    <ListItem className="career-coaching-bullet-item">
      <ListItemIcon className="career-coaching-bullets-icon">
        <CheckCircleIcon sx={{ color: "green", fontSize: 20 }} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "14px", lineHeight: "18px" }}
        primary={text}
      />
    </ListItem>
  );

  render() {
    const peekInsideButton = (
      <Button
        component={Link}
        to="/careertrack"
        className="peek-inside-button-checkout"
      >
        Peek Inside
      </Button>
    );

    const whatYouLearnItems = [
      {
        title: "Building & Deploying a Website",
        desc: `You will be hands on from day 1. We will start simple with a focus
    on practical skills. Learning through doing will maximize your
    retention and make you job-ready.`,
        icon: <ConstructionIcon className="syl-icon" />,
      },
      {
        title: "Discord Community",
        desc: `Community of learners and instructors so we can help each other as you learn and build your career.`,
        icon: <ChatIcon className="syl-icon" />,
      },
      {
        title: "Languages & Technologies",
        desc: `HTML, CSS, JavaScript, React, version control, backend, database, cloud concepts, and a LOT more. The goal is
      not to teach you every theory under the sun, but to give you a
      solid foundation and teach you where to look when you get the job.`,
        icon: <SettingsIcon className="syl-icon" />,
      },
      {
        title: "Software Engineering Fundamentals",
        desc: `You will understand how this role fits in the big picture when you
      work on a team. You will learn software engineering concepts and tools to prepare 
      you for collaboration with your future colleagues.`,
        icon: <ComputerIcon className="syl-icon" />,
      },
      {
        title: "Real World Projects",
        desc: `Learning through building is fun and engaging. You'll build several project 
        before and after you learn different concepts. 
        On the job you're often learning new material on your own to accomplish tasks. 
        The projects in this career track are designed to teach to you how you can learn independently.`,
        icon: <PublicIcon className="syl-icon" />,
      },
      {
        title: "Interview Preparation",
        desc: `We will go over common Front End and JavaScript focused interview questions as well as soft skills that make you stand out.`,
        icon: <SubtitlesIcon className="syl-icon" />,
      },
      {
        title: "Jobs",
        desc: `Understanding the job market enables you to invest your time in the right areas, negotiate a higher salary, 
        and have a more clear outlook on your career.`,
        icon: <WorkIcon className="syl-icon" />,
      },
      {
        title: "Supplemental Learning",
        desc: `Learning is a never ending journey, the same as exercising. Even after you get a job, 
        it'll be great for your career to continue learning. In this section, you will have the opportunity to delve into areas outside of frontend.
        This will not only help you communicate better with your colleagues, but also opens you up to more opportunities.`,
        icon: <SchoolIcon className="syl-icon" />,
      },
    ];

    const purchaseOptions =
      this.context.userMetadata &&
      this.context.userMetadata["fe"] &&
      this.context.userMetadata["fe"]["user_meta_enrollment_status"] ===
        txt.ENROLLED ? null : (
        <div>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            className="what-you-learn-title"
          >
            Purchase Options
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            direction={{ sm: "column", md: "row" }}
            spacing={{ sm: 5, md: 1 }}
            //   alignItems="center"
            className="career-coaching-container"
          >
            {/* <Grid item sm={1} className="career-coaching-gutter"></Grid> */}
            <Grid
              item
              xs={6}
              className="career-coaching-card single-session card-shorter"
            >
              <div className="career-coaching-card-title career-coaching-card-title-single wider-card course-only">
                <div className="career-coaching-card-title-name">Primary</div>
                <div className="career-coaching-card-title-subinfo">
                  All you really need is here
                </div>
                <div className="career-coaching-card-title-cost">$249</div>
              </div>

              <div className="career-coaching-card-desc career-coaching-card-desc-single shorter-purchase course-only-body">
                <div className="career-coaching-card-desc-text shorter-purchase-txt">
                  <div>
                    <List className="career-coaching-bullets">
                      {this.getListIcon("Online Course (1 Year Access)")}
                      {this.getListIcon("Discord Community (1 Year Access)")}
                    </List>
                  </div>
                </div>
                {this.renderBuyButton("FE_CAREER_TRACK", "course-only")}
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              className="career-coaching-card ten-session card-shorter"
            >
              <div className="career-coaching-card-title career-coaching-card-title-break-into extra">
                <div className="career-coaching-card-title-name">Extra</div>
                <div className="career-coaching-card-title-subinfo">
                  Primary <br /> + <br /> Job Prep
                </div>
                <div className="career-coaching-card-title-cost">$399</div>
              </div>
              <div className="career-coaching-card-desc-break-into shorter-purchase">
                <div className="career-coaching-card-desc-text shorter-purchase-txt">
                  <div>
                    <List className="career-coaching-bullets">
                      {this.getListIcon("Online Course (1 Year Access)")}
                      {this.getListIcon("Discord Community (1 Year Access)")}
                      {this.getListIcon("1 Resume Review")}
                      {this.getListIcon("1 Mock Interview")}
                    </List>
                  </div>
                </div>
                {this.renderBuyButton(
                  "FE_CAREER_TRACK_AND_MOCK_INTERVIEW",
                  "extra"
                )}
              </div>
            </Grid>
            {/* <Grid item xs={2} className="career-coaching-card twenty-session">
          <div className="career-coaching-card-title career-coaching-card-title-top-tech">
            <div className="career-coaching-card-title-name">Top Tech Plan</div>
            <div className="career-coaching-card-title-subinfo">
              20 Sessions <br /> + <br /> Frontend Engineer Track
            </div>
            <div className="career-coaching-card-title-cost">$1800</div>
          </div>

          <div className="career-coaching-card-desc-top-tech">
            <div className="career-coaching-card-desc-text">
              <div>
                <List className="career-coaching-bullets">
                  {this.getListIcon("Initial Personal Assessment")}
                  {this.getListIcon("Personalized Study Plan")}
                  {this.getListIcon("Frontend Track Included ($249)")}
                  {this.getListIcon("Recurring Checkups")}
                  {this.getListIcon("Resume Review")}
                  {this.getListIcon("Behavioral Interview Coaching")}
                  {this.getListIcon("Data Structure & Algorithms Coaching")}
                  {this.getListIcon("System Design Coaching")}
                  {this.getListIcon("1 on 1 Interview Practice")}
                  {this.getListIcon("Detailed Personal Feedback")}
                </List>
              </div>
            </div>
            {this.renderBuyButton("TOP_TECH_PLAN", "top-tech")}
          </div>
        </Grid> */}
            {/* <Grid item sm={1} className="career-coaching-gutter"></Grid> */}
          </Grid>
        </div>
      );

    const whatYouWillLearn = whatYouLearnItems.map((item) => (
      <ListItem
        className="list-what-you-learn"
        key={item.title}
        alignItems="flex-start"
      >
        <Divider className="divi" orientation="vertical" flexItem />
        <ListItemIcon className="syl-icon-wrapper">{item.icon}</ListItemIcon>

        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            style: { whiteSpace: "normal" },
            variant: "h6",
            component: "div",
            gutterBottom: true,
          }}
          secondary={item.desc}
          secondaryTypographyProps={{
            variant: "body1",
            component: "div",
            gutterBottom: true,
          }}
        />
      </ListItem>
    ));

    const whatYouLearn = (
      <Grid
        className="what-you-learn"
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={9}>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            className="what-you-learn-title"
          >
            Program Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <Paper elevation={3} className="what-you-learn-paper">
            <List>
              {whatYouWillLearn}
              <ListItem
                className="list-what-you-learn"
                key="readiness"
                alignItems="flex-start"
              >
                <ListItemIcon className="flag-icon-wrapper">
                  <SportsScoreIcon className="flag-icon" />
                </ListItemIcon>

                <ListItemText
                  primary="Readiness to Become a Frontend Engineer"
                  primaryTypographyProps={{
                    style: { whiteSpace: "normal" },
                    variant: "h6",
                    component: "div",
                    gutterBottom: true,
                  }}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    );

    // const yourInstructors = (
    //   <Fragment>
    //     <Typography
    //       className="instructor-title"
    //       variant="h4"
    //       component="div"
    //       gutterBottom
    //     >
    //       Your Instructors
    //     </Typography>
    //     <Grid
    //       container
    //       direction="row"
    //       justifyContent="space-between"
    //       alignItems="flex-start"
    //       className="instructors-container"
    //       spacing={2}
    //     >
    //       <Grid item md={6} xs={12}>
    //         <Instructor image={myface} blurb={txt.ARASH_INSTRUCTOR} />
    //       </Grid>
    //       <Grid item md={6} xs={12}>
    //         <Instructor image={heather} blurb={txt.HEATHER_INSTRUCTOR} />
    //       </Grid>
    //     </Grid>
    //   </Fragment>
    // );

    const continueButton = (
      <Button
        component={Link}
        variant="contained"
        color="success"
        to="/careertrack"
        className="continue-button"
      >
        Continue
      </Button>
    );

    const loadButtons = (buttons) => {
      if (
        this.context.userMetadata &&
        this.context.userMetadata["fe"] &&
        this.context.userMetadata["fe"]["user_meta_enrollment_status"] ===
          txt.ENROLLED
      ) {
        return continueButton;
      }
      if (buttons === "BOTH") {
        return (
          <Fragment>
            {this.renderBuyButton("FE_CAREER_TRACK", "fat-green-button")}{" "}
            {peekInsideButton}
          </Fragment>
        );
      } else {
        return this.renderBuyButton("FE_CAREER_TRACK", "fat-green-button");
      }
    };

    const grid = (
      <Fragment>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="checkout-container"
        >
          <Grid className="product-img-container" item xs={12}>
            <img
              className="product-img"
              src={this.props.productImage}
              alt="career track"
            />
          </Grid>
          <Grid
            container
            sx={{
              flexDirection: { xs: "column", md: "row" },
              width: { md: "100%" },
              minWidth: { md: "900px" },
            }}
            justifyContent="center"
            alignItems="center"
            className="but-peek-buttons-container"
          >
            {loadButtons("BOTH")}
          </Grid>
          <div className="reduced-price">
            1 year access (no auto renewal)
            <div> {/* <CountDown /> */}</div>
          </div>
          <Grid
            className="product-desc"
            container
            sx={{
              flexDirection: { xs: "column", md: "row" },
              width: { md: "75%", xs: "90%" },
              minWidth: { md: "900px" },
              marginLeft: { xs: "15px" },
            }}
            justifyContent="space-around"
          >
            <Grid item xs={12} md={3}>
              <Paper className="product-paper" elevation={0}>
                <Typography variant="h6" component="div" gutterBottom>
                  Prerequisites
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  Desire to learn
                </Typography>
              </Paper>
            </Grid>
            <Divider orientation="vertical" flexItem>
              <ArrowRightAltIcon
                className="arrow"
                sx={{ display: { md: "inline", xs: "none" } }}
              />
              <KeyboardArrowDownIcon
                className="arrow"
                sx={{ display: { md: "none", xs: "inline" } }}
              />
            </Divider>
            <Grid item xs={12} md={3}>
              <Paper className="product-paper" elevation={0}>
                <Typography variant="h6" component="div" gutterBottom>
                  Estimated Time to Finish
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  6-12 months <br />
                  Depending on your background you can move faster or more
                  slowly to maximize your retention. Building the projects
                  yourself throughout the track will give you the optimum
                  results, so take your time.
                  <br />
                </Typography>
              </Paper>
            </Grid>
            <Divider orientation="vertical" flexItem>
              <ArrowRightAltIcon
                className="arrow"
                sx={{ display: { md: "inline", xs: "none" } }}
              />
              <KeyboardArrowDownIcon
                className="arrow"
                sx={{ display: { md: "none", xs: "inline" } }}
              />
            </Divider>
            <Grid item xs={12} md={3}>
              <Paper className="product-paper" elevation={0}>
                <Typography variant="h6" component="div" gutterBottom>
                  Expected Results
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  Readiness for a Frontend Engineer entry level job. Ability to
                  make your own projects or websites. Feel confident to learn
                  expert level material for senior roles. Learn best practices
                  used in top tech companies.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          {/* <VideoIntro /> */}
          {purchaseOptions}
          {whatYouLearn}
          {/* {yourInstructors} */}
          <Grid item xs={12} className="bottom-enroll-button">
            {loadButtons("ONE")}
          </Grid>
        </Grid>
      </Fragment>
    );

    return grid;

    // return message ? message : productDisplay;
  }
}

export default withRouter(Checkout);
