import React from "react";
import "./AboutUs.css";
import Grid from "@mui/material/Grid";
import me from "images/me.jpg";
import snapchat from "images/snapchat.png";
import fidelitySquare from "images/fidelity-square.png";
import awsDark from "images/aws-dark-big.jpg";

class AboutUs extends React.Component {
  render() {
    return (
      <div className="about-us-wrapper">
        <Grid
          item
          className="about-us-container"
          container
          justifyContent={{ sm: "center", md: "center" }}
          alignItems={{ sm: "center", md: "flex-start" }}
          direction={{ sm: "column", md: "row" }}
        >
          <Grid item sm={5} md={2}>
            <img src={me} className="about-us-image" alt="about-us" />
          </Grid>
          <Grid item sm={4} md={5} className="about-us-container-text">
            <div className="about-us-title">Who is OSO Arash</div>
            <div className="about-us-subtext">
              Arash is a Software Engineer and Consultant who has held positions
              in the public and private sector and at companies such as Amazon,
              Fidelity, Snap, NIH, and several start ups.
            </div>
            <div className="about-us-subtext">
              Arash is passionate about teaching and believes in the power of
              having fun in education. He doesn't always speak about himself in
              3rd person but sometimes he just has to.
            </div>
            <img src={awsDark} className="company-logo" alt="aws-logo" />
            <img src={snapchat} className="company-logo" alt="snapchat-logo" />
            <img
              src={fidelitySquare}
              className="company-logo"
              alt="fidelity-logo"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AboutUs;
