export const txt = {
    blurb: `Hey there! I'm Arash.
    I can teach you how to become a wealthy engineer while having fun.`,
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    PASSWORD_RESET: 'PASSWORD_RESET',
    MUST_LOGIN_TO_ENROLL: "You must login to enroll in this track.",
    MUST_LOGIN_TO_PURCHASE: "You must login to purchase this item.",
    ENROLLED: 'ENROLLED',
    FE: 'fe',
    USER_META_: 'user_meta_',
    ENROLLMENT_STATUS: 'enrollment_status',
    ARASH_INSTRUCTOR: `Arash is a Software Engineer and Consultant who has held positions in
    the public and private sector and at companies such as Amazon,
    Fidelity, and Snap. He will be your technical instructor for this career track.`,
    HEATHER_INSTRUCTOR: `Heather is an ex biomedical engineer who pursued a leadership path. 
    She has extensive experience in hiring processes and has led teams of different sizes. 
    She is currently a senior director at a large biotech company.`
}

export default txt;