import React from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import "./Login.css";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withRouter } from "react-router-dom";
import Paper from "@mui/material/Paper";
import txt from "constants/text";
import { AuthContext } from "contexts/Context";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import osoService from "constants/settings";

const theme = createTheme();

class Login extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      loginError: null,
      subscribe: true,
      emailVerified: null,
      showPassword: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.login = this.login.bind(this);
  }

  handleCheckboxChange = () => {
    this.setState({
      subscribe: !this.state.subscribe,
    });
  };

  getUserMetadata = (user) => {
    user
      .getIdToken(/* forceRefresh */ false)
      .then((idToken) => {
        fetch(`${osoService}/get_user_metadata`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },

          //make sure to serialize your JSON body
          body: JSON.stringify({
            email: user.email,
            id_token: idToken,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (
              data != null &&
              data.message != null &&
              data.message.courses != null
            ) {
              this.context.userMetadataSetter(data.message.courses);
            } else {
              // addCourseToUser
            }
          })
          .finally(() => {});
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  login = (email, password) => {
    this.setState({
      loginError: null,
    });
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // valid credentials
        const user = userCredential.user;
        if (user && user.emailVerified) {
          const prevUrl =
            this.props.location.prevUrl &&
            !new Set(["/signup", "/login"]).has(this.props.location.prevUrl)
              ? this.props.location.prevUrl
              : "/";

          user
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              // Send token to your backend via HTTPS
              this.context.authSetter(true, user);
            })
            .catch(function (error) {
              console.error(error);
            });

          this.props.history.push({
            pathname: prevUrl,
            // state: param
          });

          this.getUserMetadata(user);
        } else {
          this.context.authSetter(false, {});
          this.setState({
            emailVerified: false,
            loginError: "Please verify your email.",
          });
        }
        // ...
      })
      .catch((error) => {
        const errorMessage = error.message.replace("Firebase: ", "");
        this.setState({
          loginError: errorMessage,
        });
        // ..
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    this.login(data.get("email"), data.get("password"));
  };

  renderVerifyEmailMessage = () => {
    const event = this.props.location.state
      ? this.props.location.state.event
      : null;
    const email = this.props.location.state
      ? this.props.location.state.email
      : null;
    if (event === txt.SIGNUP_SUCCESS) {
      return (
        <div className="verify-email">
          We just sent you an email to {email} <br /> Please verify it before
          you can login here.
        </div>
      );
    } else if (event === txt.PASSWORD_RESET) {
      return (
        <div className="verify-email">
          We just sent you a reset link to {email} <br /> Please follow the link
          to reset your password.
        </div>
      );
    } else if (event === txt.MUST_LOGIN_TO_ENROLL) {
      return <div className="login-to_enroll">{txt.MUST_LOGIN_TO_ENROLL}</div>;
    } else if (event === txt.MUST_LOGIN_TO_PURCHASE) {
      return (
        <div className="login-to_enroll">{txt.MUST_LOGIN_TO_PURCHASE}</div>
      );
    }
  };

  resetPassword = (email) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorMessage = error.message;
        this.setState({
          loginError: errorMessage,
        });
      });
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(https://storage.googleapis.com/oso-public/golden-gate.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {this.renderVerifyEmailMessage()}
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={this.handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <FormControl
                  sx={{ marginTop: 1, width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel required htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    // onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Login
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link to="/forgot-password" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to="/signup" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <div
                  hidden={!Boolean(this.state.loginError)}
                  className="signup-fail"
                >
                  {this.state.loginError}
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withRouter(Login);
