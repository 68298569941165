import React from "react";
import "./Landing.css";
import TrackCard from "components/TrackCard";
import txt from "constants/text";

class Landing extends React.Component {
  render() {
    return (
      <div className="landing-slant">
        <span className="landing-title">
          Practical Education for High Paying Jobs
        </span>
        {/* <span className="landing-subtitle">
          Transform Your Life Through Learning
        </span> */}
        <div className="fe-track-card">
          <TrackCard
            title="Frontend Engineer"
            description="Career Track"
            actions={true}
            trackId={txt.FE}
            userMetadata={this.props.userMetadata}
          />
        </div>
      </div>
    );
  }
}

export default Landing;
