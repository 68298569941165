import React, { Fragment } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import VideoCourseScreen from "components/VideoCourseScreen";
import CheckpointCourseScreen from "components/CheckpointCourseScreen";
import "./CareerTrack.css";
import osoService from "constants/settings";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import { AuthContext } from "contexts/Context";
// import NotInterestedIcon from "@mui/icons-material/NotInterested";
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import txt from "constants/text";
import { withRouter } from "react-router-dom";
import refreshToken from "utils/authUtils";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ConstructionIcon from "@mui/icons-material/Construction";
import Spinner from "components/Spinner";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomButtonRoot = styled("button")(`
  background-color: #007fff;
  padding: 15px 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  transition: all 200ms ease;
  border: none;
  float: right;
  bottom: 150px;
  right: 150px;

  &:hover {
    background-color: #0059b2;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
  }
`);

function CustomButton(props) {
  return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

class CareerTrack extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    const lessonIdFromUrl = queryParams.get("lesson");

    if (Object.keys(this.props.userMetadata).length === 0) {
      this.state = {
        open: {},
        currentCourse: null,
        currentCourseId: null,
        currentCourseWeight: null,
        currentChapter: null,
        currentTopic: null,
        currentTopicId: null,
        userMetadata: null,
        showLoginToCompleteSnackbar: false,
        showEnrollToSee: {},
        lessonIdFromUrl: lessonIdFromUrl,
      };
    } else {
      const course = props.userMetadata[this.props.courseId];
      const chapters = Object.keys(course.chapters);
      chapters.sort();
      const firstChapter = chapters[0];
      let currentChapter = firstChapter;

      if (lessonIdFromUrl) {
        // example: fe_ch01_02 -> fe_ch01
        const splits = lessonIdFromUrl.split("_");
        currentChapter = splits[0] + "_" + splits[1];
      }

      const topics = Object.keys(course.chapters[currentChapter].topics);
      topics.sort();
      const firstTopic = topics[0];
      let currentTopic = firstTopic;

      if (lessonIdFromUrl) {
        currentTopic = lessonIdFromUrl;
      }

      this.state = {
        course: course,
        open: { [currentChapter]: true },
        currentCourse: course,
        currentCourseId: course.id,
        currentCourseWeight: course.total_weight,
        currentChapterId: currentChapter,
        currentChapter: course.chapters[currentChapter],
        currentTopic: course.chapters[currentChapter].topics[currentTopic],
        currentTopicId: currentTopic,
        lessonIdFromUrl: lessonIdFromUrl,
        showEnrollToSee: {},
      };
    }

    this.handleChapterClick = this.handleChapterClick.bind(this);
    this.getChapters = this.getChapters.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.userMetadata).length === 0) {
      this.getCoursesWithLoader();
    }
  }

  getCoursesWithLoader = () => {
    this.setState(
      {
        loading: true,
      },
      this.getCourses()
    );
  };

  getCourses = () => {
    // this.setState({
    //   wait: true,
    // });

    fetch(`${osoService}/get_courses`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      //make sure to serialize your JSON body
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        const courses = data.message;
        const course = courses[this.props.courseId];
        const chapters = Object.keys(course.chapters);
        chapters.sort();
        const firstChapter = chapters[0];
        let currentChapter = firstChapter;

        if (this.state.lessonIdFromUrl) {
          // example: fe_ch01_02 -> fe_ch01
          const splits = this.state.lessonIdFromUrl.split("_");
          currentChapter = splits[0] + "_" + splits[1];
        }

        const topics = Object.keys(course.chapters[currentChapter].topics);
        topics.sort();
        const firstTopic = topics[0];
        let currentTopic = firstTopic;

        if (this.state.lessonIdFromUrl) {
          currentTopic = this.state.lessonIdFromUrl;
        }

        this.setState({
          course: course,
          open: { [currentChapter]: true },
          currentCourse: course,
          currentCourseId: course.id,
          currentCourseWeight: course.total_weight,
          currentChapterId: currentChapter,
          currentChapter: course.chapters[currentChapter],
          currentTopic: course.chapters[currentChapter].topics[currentTopic],
          currentTopicId: currentTopic,
          loading: false,
        });
      })
      .finally(() => {});
  };

  handleChapterClick = (chapterId) => {
    let open = this.state.open;
    open[chapterId] = !this.state.open[chapterId];
    this.setState({
      open: open,
    });
  };

  handleDisableMarkComplete = () => {
    this.setState({
      showLoginToCompleteSnackbar: true,
    });
  };

  handleCloseDisableMarkComplete = () => {
    this.setState({
      showLoginToCompleteSnackbar: false,
    });
  };

  handleEnrollToSee = (chapterId) => {
    const showEnrollToSee = this.state.showEnrollToSee;
    showEnrollToSee[chapterId] = true;
    this.setState({
      showEnrollToSee,
    });
  };

  handleCloseEnrollToSee = (chapterId) => {
    const showEnrollToSee = this.state.showEnrollToSee;
    showEnrollToSee[chapterId] = false;
    this.setState({
      showEnrollToSee,
    });
  };

  handleTopicClick = (topicId, topic) => {
    this.setState({
      currentTopic: topic,
      currentTopicId: topicId,
      currentChapterId: topicId.split("_")[0] + "_" + topicId.split("_")[1],
    });
    this.props.history.push({
      search: "?" + new URLSearchParams({ lesson: topicId }).toString(),
    });
  };

  handleMarkCompleted = async (completed) => {
    const prev = this.context.userMetadata;
    prev[this.state.currentCourseId].chapters[
      this.state.currentChapterId
    ].topics[this.state.currentTopicId].user_meta_completed = completed;
    this.setState({
      userMetadata: prev,
    });

    const idToken = await refreshToken(this.context.user);

    fetch(`${osoService}/mark_completed`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //make sure to serialize your JSON body
      body: JSON.stringify({
        email: this.context.user.email,
        topic_id: this.state.currentTopicId,
        user_meta_completed: completed,
        id_token: idToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({});
      })
      .finally(() => {});
  };

  getChapters = (chapters) => {
    const getSubTopics = (chapterId, topics) => {
      const subTopics = Object.keys(topics).map((topicId, index) => {
        let subInfo = null;
        let subInfoWithIcon = null;
        if (topics[topicId]["video_metadata"]) {
          const minutes = topics[topicId]["video_metadata"]["duration"]["mm"];
          const seconds = String(
            topics[topicId]["video_metadata"]["duration"]["ss"]
          ).padStart(2, "0");
          subInfo = minutes + ":" + seconds;
          subInfoWithIcon = (
            <ListItemIcon
              style={{ fontSize: "smaller" }}
              edge="end"
              aria-label="comments"
            >
              <PlayCircleFilledIcon style={{ fontSize: "medium" }} />{" "}
              <span className="sub-info">{subInfo}</span>
            </ListItemIcon>
          );
        } else if (topics[topicId]["questions"]) {
          subInfo =
            Object.keys(topics[topicId]["questions"]).length + " questions";
          subInfoWithIcon = (
            <ListItemIcon
              style={{ fontSize: "smaller" }}
              edge="end"
              aria-label="comments"
            >
              <AssignmentIcon style={{ fontSize: "medium" }} />{" "}
              <span className="sub-info">{subInfo}</span>
            </ListItemIcon>
          );
        } else {
          subInfoWithIcon = (
            <ListItemIcon
              style={{ fontSize: "smaller" }}
              edge="end"
              aria-label="comments"
            >
              <ConstructionIcon style={{ fontSize: "medium" }} />{" "}
              <span className="sub-info">under construction</span>
            </ListItemIcon>
          );
        }
        return (
          <List disablePadding key={topicId} className="topic">
            <ListItem
              className={
                topicId === this.state.currentTopicId
                  ? "general-item selected"
                  : "general-item unselected"
              }
              button
              key={topicId}
              onClick={() => {
                this.handleTopicClick(topicId, topics[topicId]);
              }}
            >
              <ListItemIcon style={{ width: "40px", minWidth: "35px" }}>
                {this.renderCompletedCheck(chapterId, topicId, topics[topicId])}
              </ListItemIcon>
              <ListItemText
                className="list-item-text"
                primary={index + 1 + ". " + topics[topicId].name}
                primaryTypographyProps={{
                  style: { fontWeight: "unset", fontSize: "unset" },
                }}
                secondary={subInfoWithIcon}
                secondaryTypographyProps={{
                  component: "span",
                }}
              />
            </ListItem>
          </List>
        );
      });

      return (
        <Collapse in={this.state.open[chapterId]} timeout="auto" unmountOnExit>
          {subTopics}
        </Collapse>
      );
    };

    const getCollapseIcon = (chapterId, chapter) => {
      if (chapter["status"] === "UNDER_CONSTRUCTION") {
        return <ConstructionIcon />;
      }
      if (chapter["tier"] !== "FREE") {
        if (
          !this.state.course ||
          this.state.course[txt.USER_META_.concat(txt.ENROLLMENT_STATUS)] !==
            "ENROLLED"
        ) {
          return (
            <Fragment>
              <Snackbar
                open={this.state.showEnrollToSee[chapterId]}
                onClose={() => this.handleCloseEnrollToSee(chapterId)}
                autoHideDuration={3000}
                message="Enroll to unlock"
              >
                <Alert
                  onClose={() => this.handleCloseEnrollToSee(chapterId)}
                  severity="info"
                  sx={{ width: "100%" }}
                >
                  Enroll to unlock
                </Alert>
              </Snackbar>
              <LockIcon onClick={() => this.handleEnrollToSee(chapterId)} />
            </Fragment>
          );
        }
      }
      return this.state.open[chapterId] ? <ExpandLess /> : <ExpandMore />;
    };

    const getCompletedCount = (topics) => {
      let completedCount = 0;
      for (let topic of Object.values(topics)) {
        if (topic["user_meta_completed"]) {
          completedCount += 1;
        }
      }
      return completedCount;
    };

    const chapterHeadings = Object.keys(chapters).map((chapterId, index) => {
      const completedCount = getCompletedCount(chapters[chapterId].topics);
      let totalTopicsCount = 0;
      for (let topic of Object.values(chapters[chapterId].topics)) {
        if (topic["video"] !== "UNDER_CONSTRUCTION") {
          totalTopicsCount += 1;
        }
      }
      const allCompleted = completedCount === totalTopicsCount;
      const completedTopicsText = allCompleted ? "Completed" : "";
      const countInfoColor = allCompleted ? "#b86dc5" : "#C8C0BF";
      let noVerticalPadding = "no-vertical-padding";

      let secondaryMsg = `${completedCount}/${totalTopicsCount} ${completedTopicsText}`;

      if (chapters[chapterId]["status"] === "UNDER_CONSTRUCTION") {
        secondaryMsg = null;
        noVerticalPadding = null;
      }

      if (chapters[chapterId]["tier"] !== "FREE") {
        if (
          !this.state.course ||
          this.state.course[txt.USER_META_.concat(txt.ENROLLMENT_STATUS)] !==
            "ENROLLED"
        ) {
          secondaryMsg = null;
          noVerticalPadding = null;
        }
      }

      return (
        <div key={chapters[chapterId].name}>
          <ListItem
            key={chapters[chapterId].name}
            button
            onClick={() => {
              this.handleChapterClick(chapterId);
            }}
            className={`chapter-heading ${noVerticalPadding}`}
          >
            <ListItemText
              primary={index + 1 + ". " + chapters[chapterId].name}
              secondary={secondaryMsg}
              secondaryTypographyProps={{
                fontSize: 12,
                color: countInfoColor,
              }}
            />
            {getCollapseIcon(chapterId, chapters[chapterId])}
          </ListItem>
          {getSubTopics(chapterId, chapters[chapterId].topics)}
        </div>
      );
    });
    return chapterHeadings;
  };

  renderCompletionPercentage = (chapters, totalWeightBackend) => {
    let totalWeight = totalWeightBackend;
    let completed = 0;
    // the commas below are needed. similar to python _ for unused vars
    for (const [, chapter] of Object.entries(chapters)) {
      for (const [, topic] of Object.entries(chapter["topics"])) {
        if ("weight" in topic) {
          // totalWeight += topic["weight"];
          if (topic["user_meta_completed"]) {
            completed += topic["weight"];
          }
        } else {
          // totalWeight += 1;
          if (topic["user_meta_completed"]) {
            completed += 1;
          }
        }
      }
    }
    return (
      <Fragment>
        <span
          className="progress-line"
          style={{
            background: `linear-gradient(to right, green ${completed}%, white ${completed}%, white)`,
          }}
        ></span>
        {completed} / {totalWeight}
      </Fragment>
    );
  };

  renderCompletedCheck = (chapterId, topicId, topic) => {
    if (topic["tier"] !== "FREE") {
      if (
        !this.state.course ||
        this.state.course[txt.USER_META_.concat(txt.ENROLLMENT_STATUS)] !==
          "ENROLLED"
      ) {
        return <LockIcon />;
      }
    }

    if (
      !this.context.userMetadata ||
      Object.keys(this.context.userMetadata).length === 0
    )
      return (
        <Tooltip title="Login to track your progress">
          {/* <NotInterestedIcon /> */}
          <CircleOutlinedIcon className="completed-icons" />
        </Tooltip>
      );
    else if (
      this.context.userMetadata[this.state.currentCourseId].chapters[
        chapterId
      ] &&
      this.context.userMetadata[this.state.currentCourseId].chapters[chapterId]
        .topics[topicId] &&
      this.context.userMetadata[this.state.currentCourseId].chapters[chapterId]
        .topics[topicId].user_meta_completed
    ) {
      return (
        <CheckCircleRoundedIcon className="completed-icons" color="success" />
      );
    } else {
      return <CircleOutlinedIcon className="completed-icons" />;
    }
  };

  renderMarkCompleted = () => {
    if (this.state.currentTopic.video === "UNDER_CONSTRUCTION") {
      return;
    }
    if (this.state.currentTopic["tier"] !== "FREE") {
      if (
        !this.state.course ||
        this.state.course[txt.USER_META_.concat(txt.ENROLLMENT_STATUS)] !==
          "ENROLLED"
      ) {
        return;
      }
    }

    if (
      !this.context.userMetadata ||
      Object.keys(this.context.userMetadata).length === 0
    ) {
      return (
        <div>
          <Snackbar
            open={this.state.showLoginToCompleteSnackbar}
            onClose={this.handleCloseDisableMarkComplete}
            autoHideDuration={4000}
            message="Login to track your progress"
          >
            <Alert
              onClose={this.handleCloseDisableMarkComplete}
              severity="warning"
              sx={{ width: "100%" }}
            >
              Login to track your progress
            </Alert>
          </Snackbar>
          <Tooltip title="Login to track your progress">
            <span className="mark-completed">
              <CustomButton
                disabled
                onClick={() => this.handleDisableMarkComplete()}
              >
                Mark Completed
              </CustomButton>
            </span>
          </Tooltip>
        </div>
      );
    }

    if (
      this.context.userMetadata &&
      this.context.userMetadata[this.state.currentCourseId] &&
      this.context.userMetadata[this.state.currentCourseId].chapters[
        this.state.currentChapterId
      ] &&
      this.context.userMetadata[this.state.currentCourseId].chapters[
        this.state.currentChapterId
      ].topics[this.state.currentTopicId].user_meta_completed
    ) {
      return (
        <Button
          variant="contained"
          color="secondary"
          className="completed-button"
          onClick={() => this.handleMarkCompleted(false)}
        >
          Completed
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="success"
          onClick={() => this.handleMarkCompleted(true)}
        >
          Mark Completed
        </Button>
      );
    }
  };

  renderMainScreen() {
    const type = this.state.currentTopic["type"];
    if (type == null) {
      return <VideoCourseScreen topic={this.state.currentTopic} />;
    }
    if (type === "checkpoint") {
      return (
        <CheckpointCourseScreen
          topic={this.state.currentTopic}
          currentTopicId={this.state.currentTopicId}
        />
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }
    if (!this.state.currentCourse || !this.context.userMetadata)
      return <div></div>;
    return (
      <div className="career-track">
        <div className="side-panel">
          <List
            subheader={
              <ListSubheader className="track-name">
                {this.state.currentCourse.name}
                {this.renderCompletionPercentage(
                  this.state.currentCourse.chapters,
                  this.state.currentCourse.total_weight
                )}
              </ListSubheader>
            }
            className="list"
          >
            {this.getChapters(this.state.currentCourse.chapters)}
          </List>
        </div>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          wrap="nowrap"
          className="main-course-screen"
        >
          {this.renderMainScreen()}
          <Grid
            className="completed-button-wrapper"
            container
            justifyContent="flex-end"
          >
            {this.renderMarkCompleted()}
          </Grid>
          <div className="bottom-panel">
            <List
              subheader={
                <ListSubheader className="track-name">
                  {this.state.currentCourse.name}
                  {this.renderCompletionPercentage(
                    this.state.currentCourse.chapters,
                    this.state.currentCourse.total_weight
                  )}
                </ListSubheader>
              }
              className="list"
            >
              {this.getChapters(this.state.currentCourse.chapters)}
            </List>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withRouter(CareerTrack);
