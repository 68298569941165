import React, { Component } from "react";
import "./Logo.css";
import { Link } from "react-router-dom";

export default class Logo extends Component {
  render() {
    return (
      <Link to="/" className="logo">
        OSO
      </Link>
    );
  }
}
