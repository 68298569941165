import React from "react";
import Grid from "@mui/material/Grid";
import "./PrivacyPolicy.css";
import { Link } from "react-router-dom";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Grid
        container
        rowSpacing={1}
        direction="column"
        className="policy-container"
      >
        <Grid item xs={4} className="main-policy-title">
          Privacy Policy
        </Grid>
        <Grid item xs={4} className="policy-body">
          You know how on a shampoo bottle, it says "don't drink this! and if
          you do, it's not our responsibility." This page is something like
          that. So here it goes: <br />
          This page explains how we treat the information you share with us when
          you visit or use the website or engage with OSO Engineer in any form.
          We respect your right to privacy. Your use of website indicates
          acceptance of this policy. If you don't agree with any of the terms,
          please refrain from using this website.
        </Grid>
        <Grid item xs={4} className="policy-title">
          What information do we collect?
        </Grid>
        <Grid item xs={4} className="policy-body">
          When you subscribe, sign up, or enroll in a course or career track, we
          collect information such as your email address so you can track your
          progress and we can communicate with you. We may send you marketing
          emails or just for fun. <br />
          We use Stripe to handle your payment because we think they know what
          they're doing. Please read their{" "}
          <a href="https://stripe.com/privacy">Privacy Policy</a> for more
          information. <br />
          We may provide you an option to login with your other social media
          account in which case we'd ask for necessary social media information
          to log you in. We'll NEVER post anything to any of your social media
          accounts without your permission.
        </Grid>
        <Grid item xs={4} className="policy-title">
          How do we use your information?
        </Grid>
        <Grid item xs={4} className="policy-body">
          We mainly use your email or information that you provide to create a
          better experience for you using the website. To create your account,
          process your payment, save your progress, and other use cases in the
          website, we use the data you provide. To offer support, or
          administrative notices. And finally to abide by any legal
          requirements.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Who do we share you information with?
        </Grid>
        <Grid item xs={4} className="policy-body">
          Third party services such as Google analytics, stripe or security
          services such as reCAPTCHA. Please see their respective privacy
          policies. We're not responsible if any of these services misuse your
          information (you would think I wouldn't need to write this; remember
          the shampoo bottle?). Your information may travel internationally to
          get to any of our cloud servers or these third party services. Most if
          not all of these services are in the United States.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Your consent
        </Grid>
        <Grid item xs={4} className="policy-body">
          By visiting this website, signing up, making a purchase, or using it
          in any form you imply your consent regarding the information collected
          and agreement to use this website according to the{" "}
          <Link to="/terms">Terms of Service</Link>.
        </Grid>
        <Grid item xs={4} className="policy-title">
          How do I withdraw my consent
        </Grid>
        <Grid item xs={4} className="policy-body">
          You can delete your account or unsubscribe by contacting
          arash@oso.engineer. I'm the only person working here :) I will add a
          feature soon, so you can delete with a button. Hopefully not a popular
          feature. Your data will persist until you don't want it to.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Security of your information
        </Grid>
        <Grid item xs={4} className="policy-body">
          We use TLS when transmitting any information. We DO NOT store your
          credit card information. This site is also protected by reCAPTCHA and
          the Google&nbsp;
          <a href="https://policies.google.com/privacy">Privacy Policy</a>
          &nbsp;and&nbsp;
          <a href="https://policies.google.com/terms">Terms of Service</a>
          &nbsp;apply. <br />
          We do a few other things that we're not gonna tell you in case you're
          a hacker. So in case you're not, you're safer now. You're welcome!
        </Grid>
        <Grid item xs={4} className="policy-title">
          Age
        </Grid>
        <Grid item xs={4} className="policy-body">
          By using this website you imply that you're at the age to consent for
          yourself and determine what section of the website to use and what
          sections to avoid. No information is knowingly collected for children
          under the age of 13.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Other information collected
        </Grid>
        <Grid item xs={4} className="policy-body">
          For security and analytics purposes, other information such as ip
          address, device information may be automatically collected.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Devices
        </Grid>
        <Grid item xs={4} className="policy-body">
          The terms of this policy applies to any device you use it with.
          Whether you use your desktop, laptop, tablet, smartphone, (or if your
          fridge is so advanced that can pull up a website) this policy applies.
          We will collect device information only for analytics or to improve
          your experience using this website.
        </Grid>
        <Grid item xs={4} className="policy-title">
          Changes to Privacy Policy
        </Grid>
        <Grid item xs={4} className="policy-body">
          We're a growing website, so we reserve the rights to modify this
          policy without notice and changes go into effect immediately.
        </Grid>
      </Grid>
    );
  }
}

export default PrivacyPolicy;
